import classNames from 'classnames';
import React from 'react';

import { Checkbox } from '../Checkbox/Checkbox';
import { Input } from '../Input/Input';

import './fields.scss';

const components = {
  input: Input,
  checkbox: Checkbox,
};

export const Fields = ({ config, register, errors, className, itemInRow }) => (
  <div
    className={classNames('fields', className, {
      [`fields--row-${itemInRow}`]: true,
    })}
  >
    {config.map((item, idx) => {
      const Component = components[item.type] || Input;
      delete item.type;

      return (
        <div key={idx} className='fields__item'>
          <Component {...register(item.name, item.rules)} {...item} error={!!errors[item.name]} />
        </div>
      );
    })}
  </div>
);
