export const pagesTypes = {
  list: {
    request: 'pages/list/request',
    reject: 'pages/list/reject',
    success: 'pages/list/success',
  },
  count: {
    set: 'pages/count/set',
  },
  pagination: {
    setPage: 'pages/pagination/setPage',
    setLimit: 'pages/pagination/setLimit',
  },
  search: {
    set: 'pages/search/set',
  },
};
