import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from '../../components/Modal/Modal';
import { Input, Button } from '../../baseComponents';
import { updateSpecialValues } from '../../newStore/actions/specialValues';

export const EditSpecialValues = ({ id, resetCurrent }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { list } = useSelector((state) => state.specialValues);
  const { register, handleSubmit, reset, setValue } = useForm();

  const currentSpecialValues = list.find((item) => item.ID === id);

  useEffect(() => {
    if (currentSpecialValues) {
      setValue('value', currentSpecialValues.value);
    }
  });

  const onSubmit = (data) => {
    dispatch(updateSpecialValues({ ...currentSpecialValues, ...data }));
    setError(null);
  };

  const onReset = () => {
    resetCurrent();
    reset();
    setError(null);
  };

  return (
    <Modal id='editSpecialValues' onHide={onReset}>
      <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
      <form className='add-form' onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder='value' {...register('value')} />
        <Button className='add-form__button' type='submit' style={{ marginTop: 15 }}>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
