import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteStream } from '../../newStore/actions/streams';
import { Modal } from '../../components/Modal/Modal';

import { closeModal } from '../../newStore/actions/modal';

export const DeleteConfirmModal = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <Modal className='delete-confirm' id='delete_confirm--stream'>
      <h2>Подтверждение действия</h2>
      <div className='delete-confirm__buttons'>
        <button
          className='delete-confirm__button'
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onClick={() => dispatch(deleteStream(id))}
        >
          Да
        </button>
        <button
          className='delete-confirm__button'
          onClick={() => dispatch(closeModal('delete_confirm--stream'))}
        >
          Нет
        </button>
      </div>
    </Modal>
  );
};
