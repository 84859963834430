import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { affilatesFetch } from '../../newStore/actions/affilates';
import { prepareList } from '../../utils/affilates';

import './affilates.scss';

export const Affilates = () => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector((state) => state.affilates);

  useEffect(() => {
    dispatch(affilatesFetch());
  }, []);

  const l = prepareList(list);

  return !loading ? (
    <div className='affilates'>
      <div className='affilates__list'>
        <div className='affilates__list-head'>
          {Object.values(Object.keys(l[0] || {})).map((item) => (
            <div>{item}</div>
          ))}
        </div>
        <div className='affilates__list-body'>
          {l.map((a) => (
            <Link to={`/current_affilate/${a.tg_id}`}>
              {Object.values(a).map((value) => {
                if (Array.isArray(value)) {
                  return (
                    <div className='affilates__list-body-counter'>
                      {value.map((item) => (
                        <div>{item}</div>
                      ))}
                    </div>
                  );
                }

                return <div>{value}</div>;
              })}
            </Link>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <h2>Loading</h2>
  );
};
