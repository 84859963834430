import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import parcsISO from 'date-fns/parseISO';
import { useDispatch, useSelector } from 'react-redux';

import { openModal } from '../../newStore/actions/modal';
import { onelinksFetch } from '../../newStore/actions/onelink';
import './oneLink.scss';
import { DeleteConfirmModalOnelink } from './Confirm';
import { AddOnelink } from './AddOnelink';
import { CloneOnelink } from './CloneOnelink';

export const OneLink = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [currentId, setCurrentId] = useState(null);

  const { loading, list } = useSelector((state) => state.onelinks);

  useEffect(() => {
    dispatch(onelinksFetch());
  }, []);

  const onCreate = () => dispatch(openModal('addOnelink'));

  const onEdit = (editedId) => {
    setCurrentId(editedId);
    dispatch(openModal('addOnelink'));
  };

  const onClone = (editedId) => {
    setCurrentId(editedId);
    dispatch(openModal('cloneOnelink'));
  };

  const onDelete = (deleteId) => {
    setId(deleteId);
    dispatch(openModal('delete_confirm--onelink'));
  };

  return (
    <div className='one-link'>
      {!loading ? (
        <>
          <button className='one-link__button' onClick={onCreate}>
            Создать
          </button>
          <div className='one-link__head'>
            <div style={{ visibility: 'hidden' }} />
            <div>Id</div>
            <div>UpdatedAt</div>
            <div>Visible</div>
            <div>App_name</div>
            <div>Pwd</div>
            <div>Key</div>
            <div>Link</div>
            <div>Type</div>
            <div>Use_after_ban</div>
            <div>Package</div>
            <div>Description</div>
            <div>GEOS</div>
            <div style={{ visibility: 'hidden' }} />
          </div>
          <div className='one-link__list'>
            {list &&
              list.map((item, index) => (
                <div className='one-link__list-item' kye={index}>
                  <div className='button-cell'>
                    <button className='one-link__button' onClick={() => onEdit(item.ID)}>
                      Редактировать
                    </button>
                  </div>
                  <div>{`${item.ID}`}</div>
                  {item.UpdatedAt ? (
                    <div>{`${format(parcsISO(item.UpdatedAt), 'yyyy-MM-dd:HH:mm')}`}</div>
                  ) : (
                    <div>null</div>
                  )}
                  <div>{`${item.visible}`}</div>
                  <div>{`${item.app_name}`}</div>
                  <div>{`${item.pwd}`}</div>
                  <div>{`${item.key}`}</div>
                  <div>{`${item.link}`}</div>
                  <div>{`${item.type === 0 ? 'Android' : 'IoS'}`}</div>
                  <div>{`${item.use_after_ban}`}</div>
                  <div>{`${item.package}`}</div>
                  <div>{`${item.description}`}</div>
                  <div className='geos__column'>
                    {item && item.geos && item.geos.map((i, idx) => <div key={idx}>{i.name}</div>)}
                  </div>
                  <div className='button-cell'>
                    <button
                      className='one-link__button one-link__button--delete'
                      onClick={() => onDelete(item.ID)}
                    >
                      Удалить
                    </button>
                    <button
                      className='one-link__button one-link__button--clone'
                      onClick={() => onClone(item.ID)}
                    >
                      Клонировать
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <h2>Loading</h2>
      )}
      <DeleteConfirmModalOnelink id={id} />
      <AddOnelink id={currentId} resetCurrent={() => setCurrentId(null)} />
      <CloneOnelink id={currentId} resetCurrent={() => setCurrentId(null)} />
    </div>
  );
};
