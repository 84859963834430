import { locationTypes } from '../types/locations';

const initialState = {
  list: [],
  loading: false,
};

export const locations = (state = initialState, action) => {
  switch (action.type) {
    case locationTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case locationTypes.success:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case locationTypes.error:
      return {
        ...state,
        loading: false,
      };
    case locationTypes.save:
      return {
        ...state,
        list: state.list.map((item) => (item.ID === action.payload.ID ? action.payload : item)),
      };
    case locationTypes.add:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    case locationTypes.delete:
      return {
        ...state,
        list: state.list.filter((item) => item.ID !== action.payload),
      };
    default:
      return state;
  }
};
