import { combine, createEffect, createEvent, createStore, forward } from 'effector';
import { createGate } from 'effector-react';
import { api } from '../api';
import { dataFilterOptions } from '../utils/dataFilter';

export const $tableLimit = createStore(25);

export const $allCounts = createStore([]);

export const $dates = createStore(dataFilterOptions[0].value);

export const setDate = createEvent();

export const $pagesCount = createStore(1);

export const $pages = createStore([]);

export const setTableLimit = createEvent();

$tableLimit.on(setTableLimit, (_, data) => data);
$dates.on(setDate, (_, data) => data);

export const getPagesCountFx = createEffect(async ({ limit = 25 }) => {
  try {
    const response = await api.get('get_pages_count');

    return response.data / limit;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
});

export const pagesCountFetch = createGate();

forward({
  from: pagesCountFetch.open,
  to: getPagesCountFx,
});

$pagesCount.on(getPagesCountFx.done, (_, { result }) => result);

export const countsFetchFx = createEffect(async ({ date = [], page = 0, limit = 25 }) => {
  let pages;

  if (date[0] && !date[1]) {
    pages = await api.get(`get_pages?&limit=${limit}&page=${page}&start_date=${date[0]}`);
  } else if (date[0] && date[1]) {
    pages = await api.get(
      `get_pages?&limit=${limit}&page=${page}&start_date=${date[0]}&end_date=${date[1]}`
    );
  } else {
    pages = await api.get(`get_pages?&limit=${limit}&page=${page}`);
  }

  return {
    pages: pages.data,
    list: pages.data?.map((item, index) => ({
      ...item,
      domain: pages.data[index]?.domain,
      bots: item.white_count,
      users: item.black_count,
      geos: pages.data[index].geos?.map((geo) => geo.name),
      id: pages.data[index]?.ID,
      lost_count: item.lost_count,
      keywords: item.keywords,
      white: `(${item.type}) ${item.white_page}`,
      pages,
    })),
  };
});

export const countsFetch = createGate();

forward({
  from: countsFetch.open,
  to: countsFetchFx,
});

$allCounts.on(countsFetchFx.done, (_, { result }) => result);
$pages.on(countsFetchFx.done, (_, { result }) => result.pages);

// const createPagesFetch = (pages, type, dates) => {
//   if (!dates[0] || !dates[1]) {
//     return pages.map((item) => api.get(`/get_${type}_count?domain=${item.domain}`));
//   }
//   return pages.map((item) =>
//     api.get(`/get_${type}_count?domain=${item.domain}&start_date=${dates[0]}&end_date=${dates[1]}`)
//   );
// };

export const counts = combine({
  data: $allCounts,
  loading: countsFetchFx.pending,
});
