export const ftpListTypes = {
  loading: 'ftpList/loading',
  success: 'ftpList/success',
  error: 'ftpList/error',
  delete: 'ftpList/delete',
};

export const newFtpListTypes = {
  success: 'newFtpList/success',
};
