import { offerTypes } from '../types/offer';

import { api } from '../../api';
import { closeModal } from './modal';

export const fetchOffers = () => async (dispatch) => {
  dispatch({ type: offerTypes.loading });

  try {
    const { data } = await api.get('get_rent_offers');

    dispatch({ type: offerTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: offerTypes.reject });
  }
};

export const saveOffer = (value) => async (dispatch) => {
  try {
    const { data } = await api.post('save_rent_offer', value);

    dispatch({ type: offerTypes.save, payload: data });
    dispatch(closeModal('offer-edit'));
  } catch (e) {
    dispatch({ type: offerTypes.reject });
  }
};
