import { managersTypes } from '../types/managers';

const initialState = {
  loading: false,
  list: [],
};

export const managers = (state = initialState, action) => {
  switch (action.type) {
    case managersTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case managersTypes.reject:
      return {
        ...state,
        loading: false,
      };
    case managersTypes.success:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case managersTypes.add:
      return {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      };
    case managersTypes.edit:
      return {
        ...state,
        loading: false,
        list: state.list.map((item) => {
          if (item.ID === action.payload.ID) {
            return action.payload;
          }

          return item;
        }),
      };
    default:
      return state;
  }
};
