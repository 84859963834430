export const domainsTypes = {
  loading: 'domains/loading',
  success: 'domains/success',
  error: 'domains/error',
  update: 'domains/update',
};

export const domainZonesTypes = {
  loading: 'domains_zones/loading',
  success: 'domains_zones/success',
  error: 'domains_zones/error',
  create: 'domains_zones/create',
  createSuccess: 'domains_zones/createSuccess',
};
