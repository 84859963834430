import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import './filters.scss';
import { pagesTypes } from '../../newStore/types/pages';
import { setDate } from '../../store/counts';
import CreateModal from '../Modals/CreateModal/CreateModal';
import { AddGeo } from '../AddGeo';
import { DataFilter } from '../DataFilter';
import { openModal } from '../../newStore/actions/modal';
import { AddFile } from '../AddFile';
import { Button } from '../../baseComponents';
import { Search } from '../Search';

export const Filters = () => {
  const dispatch = useDispatch();
  const [, setDates] = useState([]);

  const onChangeData = (start, end) => {
    setDates([start, end]);
  };

  const onSearch = (value) => {
    dispatch({ type: pagesTypes.search.set, payload: value });
    dispatch({ type: pagesTypes.pagination.setPage, payload: 0 });
  };

  return (
    <div className='filters'>
      <Search onSearch={onSearch} minSearchLength={3} />
      <DataFilter setDate={setDate} onChange={onChangeData} />
      <AddGeo />
      <AddFile text='Добавить файл' uploadUrl='upload_file_ftp_endpoint' />
      <AddFile text='Добавить файл (preland)' uploadUrl='upload_preland_ftp' />
      <Button style={{ marginLeft: 30 }} onClick={() => dispatch(openModal('ftpList'))}>
        FtpList
      </Button>
      <Button style={{ marginLeft: 30 }} onClick={() => dispatch(openModal('editGeo'))}>
        Редактировать GEO
      </Button>
      <Button style={{ marginLeft: 30 }} onClick={() => dispatch(openModal('pftps'))}>
        Pftps
      </Button>
      <Button className='filters__create-button' onClick={() => dispatch(openModal('create'))}>
        Создать
      </Button>

      <CreateModal />
    </div>
  );
};
