import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../baseComponents';
import { closeModal } from '../../../newStore/actions/modal';

import { returnPage } from '../../../newStore/actions/pages';

import { Modal } from '../../Modal';

export const ReturnUrlConfirm = () => {
  const dispatch = useDispatch();
  const { page: editPage } = useSelector((state) => state.update);

  return (
    <Modal id='return_url_confirm'>
      <div style={{ padding: 20 }}>
        <h4>Уверены ли вы что надо вернуть домен в магазин?</h4>
        <div className='delete-confirm__buttons'>
          <Button
            color='dangers'
            onKeyPress={(e) => {
              if (e.keyCode === 13) e.preventDefault();
            }}
            onClick={() => dispatch(returnPage(editPage.ID))}
          >
            Да
          </Button>
          <Button onClick={() => dispatch(closeModal('return_url_confirm'))}>Нет</Button>
        </div>
      </div>
    </Modal>
  );
};
