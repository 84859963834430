import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openModal } from '../../newStore/actions/modal';
import { pwagsFetch } from '../../newStore/actions/pwag';
import { Button } from '../../baseComponents';
import { Add } from './Add/Add';
import { DeleteConfirmModal } from './Confirm';
import { ReactComponent as DeleteIcon } from './assets/trash.svg';
import './pwags.scss';

export const Pwags = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const { list, loading } = useSelector((state) => state.pwags);
  const history = useHistory();

  useEffect(() => {
    dispatch(pwagsFetch());
  }, []);

  const onDelete = (deleteId) => {
    setId(deleteId);
    dispatch(openModal('delete_confirm--pwag'));
  };

  const onCreate = () => dispatch(openModal('addPwag'));

  return (
    <div className='pwas'>
      {!loading ? (
        <>
          <Button onClick={onCreate}>Создать</Button>
          <div className='pwas__head'>
            <div style={{ visibility: 'hidden' }} />
            <div>visible</div>
            <div>pwd</div>
            <div>app_name</div>
            <div>priority</div>
            <div>joxy</div>
            <div style={{ visibility: 'hidden' }} />
          </div>
          <div className='pwas__list'>
            {list.map((item, index) => (
              <div className='pwas__list-item' key={index}>
                <div className='button-cell'>
                  <Button onClick={() => history.push(`/current_pwag/${item.ID}`)}>
                    Редактировать
                  </Button>
                </div>
                <div>{`${item.visible}`}</div>
                <div>{`${item.pwd}`}</div>
                <div>{`${item.app_name}`}</div>
                <div>{`${item.priority}`}</div>
                <a
                  href={item.joxy}
                  style={{
                    pointerEvents: item.joxy ? 'all' : 'none',
                    display: 'block',
                  }}
                  target='_blank'
                  rel='noreferrer'
                >{`${item.joxy}`}</a>
                <div className='button-cell'>
                  <button
                    className='pwas__button pwas__button--delete'
                    onClick={() => onDelete(item.ID)}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <h2>Loading</h2>
      )}
      <DeleteConfirmModal id={id} />
      <Add />
    </div>
  );
};
