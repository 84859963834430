import { UsersTypes } from '../types/users';

const initialState = {
  loading: false,
  list: [],
  secondList: null,
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case UsersTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case UsersTypes.error:
      return {
        ...state,
        loading: false,
      };
    case UsersTypes.success:
      return {
        ...state,
        list: action.payload.list,
        pages: action.payload.pages,
        loading: false,
      };
    case UsersTypes.secondTable.success:
      return {
        ...state,
        secondList: action.payload,
      };
    default:
      return state;
  }
};
