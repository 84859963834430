import classNames from 'classnames';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { createDomain } from '../../newStore/actions/domains';
import { Button, Checkbox, Input } from '../../baseComponents';

import './addDomain.scss';
import { getZonesOptions } from '../../utils/domains';

export const AddDomain = () => {
  const dispatch = useDispatch();
  const { domainZonesList } = useSelector((state) => state.domains);
  const [isActive, setIsActive] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(createDomain(data));
    reset();
  };

  return (
    <div className='add-domain'>
      <Button onClick={() => setIsActive((prev) => !prev)}>Создать домен</Button>
      <div
        className={classNames('add-domain__dropdown', {
          'add-domain__dropdown--active': isActive,
        })}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type='text'
            placeholder='Domain'
            {...register('domain', { required: true })}
            error={!!errors.domain}
          />
          <Checkbox label='activated' {...register('activated')} />
          <Controller
            control={control}
            name='zone'
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <>
                <Select
                  className='create__geos'
                  options={getZonesOptions(domainZonesList)}
                  value={getZonesOptions(domainZonesList)?.find(
                    (item) => item.value === field.value
                  )}
                  onChange={(val) => field.onChange(val.value)}
                  placeholder='Zone'
                />
                <span style={{ color: 'red ' }}>{errors.zone?.message}</span>
              </>
            )}
          />
          <Button type='submit'>Создать</Button>
        </form>
      </div>
    </div>
  );
};
