import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

import { helpFetch } from '../../newStore/actions/help';

import './help.scss';

export const Help = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.help);

  useEffect(() => dispatch(helpFetch()), []);

  return (
    <div className='help'>
      {!loading ? (
        <>
          <h2>Оглавление:</h2>
          <div className='help__links'>
            {list?.map((item, index) => (
              <HashLink className='help__links-item' to={`#${item.title}`} key={index}>
                {item.title}
              </HashLink>
            ))}
          </div>
          <div className='help__list'>
            {list?.map((item, index) => (
              <div className='help__list-item' id={item.title} key={index}>
                <div className='help__list-item-title'>{item.title}</div>
                <pre
                  className='help__list-item-body'
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <h2>Loading</h2>
      )}
    </div>
  );
};
