import React from 'react';

import './app.scss';
import { Route, Switch } from 'react-router-dom';
import SuccessModal from './components/Modals/SuccessModal';
import ErrorModal from './components/Modals/ErrorModal';
import { getToken } from './utils/getToken';
import { Login } from './components/Login';
import {
  CloBlock,
  Errors,
  Help,
  Main,
  Managers,
  ManagersInfo,
  Offer,
  Ping,
  Price,
  Profit,
  Streams,
  OneLink,
  SpecialValues,
} from './pages';
import { Current } from './pages/Current';
import { Header } from './components/Header';
import { Domains } from './pages/Domains/Domains';
import { BotText } from './pages/BotText';
import { Users } from './pages/Users/Users';
import { Affilates } from './pages/Affilates';
import { CurrentAffilate } from './pages/CurrentAffilate';
import { Pwags } from './pages/Pwags';
import { CurrentPwag } from './pages/CurrentPwag/CurrentPwag';
import { Images } from './pages/Images/Images';
import { PwagTranslates } from './pages/PwagTanslates/PwagTranslates';
import { Locations } from './pages/Locations';
import { IosApp } from './pages/IosApp';

const App = () => {
  return (
    <div className='main'>
      {getToken() ? (
        <>
          <Header />
          <Switch>
            <Route exact path='/' component={Main} />
            <Route path='/current/:id' component={Current} />
            <Route path='/managers_info' component={ManagersInfo} />
            <Route path='/errors' component={Errors} />
            <Route path='/domains' component={Domains} />
            <Route path='/help' component={Help} />
            <Route path='/streams' component={Streams} />
            <Route path='/bot_text' component={BotText} />
            <Route path='/users' component={Users} />
            <Route path='/affilates' component={Affilates} />
            <Route path='/current_affilate/:id' component={CurrentAffilate} />
            <Route path='/pwags' component={Pwags} />
            <Route path='/current_pwag/:id' component={CurrentPwag} />
            <Route path='/images' component={Images} />
            <Route path='/pwag_translates' component={PwagTranslates} />
            <Route path='/locations' component={Locations} />
            <Route path='/ping' component={Ping} />
            <Route path='/managers' component={Managers} />
            <Route path='/iosApp' component={IosApp} />
            <Route path='/profit' component={Profit} />
            <Route path='/price' component={Price} />
            <Route path='/offers' component={Offer} />
            <Route path='/clo_block' component={CloBlock} />
            <Route path='/onelink' component={OneLink} />
            <Route path='/special_values' component={SpecialValues} />
          </Switch>
        </>
      ) : (
        <Login />
      )}
      <SuccessModal />
      <ErrorModal />
    </div>
  );
};

export default App;
