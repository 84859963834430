import { ftpListTypes, newFtpListTypes } from '../types/ftpList';

const initialState = {
  ftpsList: [],
  list: [],
  loading: false,
};

export const ftpList = (state = initialState, action) => {
  switch (action.type) {
    case ftpListTypes.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case ftpListTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case ftpListTypes.error:
      return {
        ...state,
        loading: false,
      };
    case newFtpListTypes.success:
      return {
        ...state,
        ftpsList: action.payload,
      };
    case ftpListTypes.delete:
      return {
        ...state,
        ftpsList: state.ftpsList.filter((item) => item.ID !== action.payload),
      };
    default:
      return state;
  }
};
