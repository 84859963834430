import React from 'react';
import classNames from 'classnames';

import './button.scss';

export const Button = ({
  children,
  onClick,
  loading,
  disabled,
  type = 'button',
  color,
  style,
  className,
}) => (
  <button
    className={classNames('button', className, {
      'button--loading': loading,
      'button--disabled': disabled,
      'button--dangers': color === 'dangers',
    })}
    onClick={onClick}
    type={type}
    style={style}
    disabled={disabled}
  >
    {children}
  </button>
);
