import React from 'react';
import classNames from 'classnames';

import './input.scss';

const controls = {
  input: React.forwardRef(({ ...props }, ref) => <input {...props} ref={ref} />),
  number: React.forwardRef(({ ...props }, ref) => <input {...props} type='number' ref={ref} />),
  textarea: React.forwardRef(({ ...props }, ref) => <textarea {...props} ref={ref} />),
};

export const Input = React.forwardRef(
  (
    {
      defaultValue,
      className,
      color,
      name,
      onClick,
      onBlur,
      onChange,
      placeholder,
      style,
      error,
      success,
      label,
      loading,
      type = 'text',
      value,
      as = 'input',
      ...props
    },
    ref
  ) => {
    const classes = classNames('input', className, {
      'input--primary': color === 'primary',
      'input--secondary': color === 'secondary',
      'input--error': !!error,
      'input--success': !!success,
      'input--loading': !!loading,
    });

    const isStatusActive = !!loading || !!success;

    const RootControl = controls[as];

    return (
      <div className={classes}>
        {label && (
          <label className='label' htmlFor={name}>
            {label}
          </label>
        )}
        <div className='control-wrap'>
          <RootControl
            ref={ref}
            className='control'
            name={name}
            onClick={onClick}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            style={style}
            data-placeholder={placeholder}
            disabled={!!loading}
            defaultValue={defaultValue || undefined}
            type={type}
            value={value}
            {...props}
          />
          <span className='input-placeholder' data-placeholder={placeholder}>
            {placeholder}
          </span>
          {isStatusActive && <div className='input-status' />}
        </div>
        {/* {error && <span className="input__error">{error}</span>} */}
      </div>
    );
  }
);
