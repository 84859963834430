import { errorPageTypes } from '../types/errorPage';

import { api } from '../../api';

export const errorPageFetch = () => async (dispatch) => {
  dispatch({ type: errorPageTypes.loading });

  try {
    const { data } = await api.get('get_errors');

    dispatch({ type: errorPageTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: errorPageTypes.reject });
  }
};
