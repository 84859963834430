import { api } from '../../api';
import { affilatesTypes } from '../types/affilates';

export const affilatesFetch = () => async (dispatch) => {
  try {
    dispatch({ type: affilatesTypes.list.loading });

    const { data } = await api.get('get_affilates');

    dispatch({ type: affilatesTypes.list.success, payload: data });
  } catch (e) {
    dispatch({ type: affilatesTypes.list.error });
  }
};

export const affilatesCurrentFetch = () => async (dispatch) => {
  try {
    dispatch({ type: affilatesTypes.list.loading });

    const { data } = await api.get('get_affilates');

    dispatch({ type: affilatesTypes.list.success, payload: data });
  } catch (e) {
    dispatch({ type: affilatesTypes.list.error });
  }
};

export const affilatesClientsFetch =
  (url, id, page = 0, limit = 25) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(url, {
        params: { page, limit, affilate_id: id },
      });

      dispatch({ type: affilatesTypes.clients.success, payload: data });
    } catch (e) {
      dispatch({ type: affilatesTypes.list.error });
    }
  };
