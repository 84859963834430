import { UsersTypes } from '../types/users';

import { api } from '../../api';

export const fetchCurrentUsersList =
  (list, startDate, endDate, page, limit, botEventNum) => async (dispatch) => {
    try {
      dispatch({ type: UsersTypes.loading });

      const { data } = await api.get(list, {
        params: {
          start_date: startDate,
          end_date: endDate,
          page,
          limit,
          event_num: list === 'get_bot_events' ? botEventNum : null,
        },
      });

      dispatch({
        type: UsersTypes.success,
        payload: {
          list: data.list || data,
          pages: data.count || 10,
        },
      });
    } catch (e) {
      dispatch({ type: UsersTypes.error });
    }
  };

export const userBotEventFetch = (startDate, endDate) => async (dispatch) => {
  try {
    const { data } = await api.get('get_bot_events_dash', {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });

    dispatch({
      type: UsersTypes.secondTable.success,
      payload: data,
    });
  } catch (e) {
    dispatch({ type: UsersTypes.error });
  }
};
