import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorPageFetch } from '../../newStore/actions/errorPage';

import './errorsInfo.scss';

export const Errors = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.errorPage);

  useEffect(() => {
    dispatch(errorPageFetch());
  }, []);

  return (
    <div className='errors-info'>
      {!loading ? (
        <div className='errors-info__table'>
          <div className='errors-info__table-head'>
            <div>CreatedAt</div>
            <div>Place</div>
            <div>Error</div>
            <div>Count</div>
          </div>
          <div className='errors-info__table-body'>
            {list.map((item) => (
              <div className='errors-info__table-item' key={item.ID}>
                <div>{item.CreatedAt}</div>
                <div>{item.place}</div>
                <div>{item.error}</div>
                <div>{item.count}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h1>Loading</h1>
      )}
    </div>
  );
};
