import React from 'react';
import { useDispatch } from 'react-redux';
import { uploadFile } from '../../newStore/actions/mainPage';

export const AddDomainList = () => {
  const dispatch = useDispatch();

  const onChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      dispatch(uploadFile(file));
    }
  };

  return (
    <label className='button'>
      Загрузить список доменов
      <input onChange={onChange} type='file' style={{ display: 'none' }} />
    </label>
  );
};
