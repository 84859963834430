import { pingTypes } from '../types/ping';

const initialState = {
  list: [],
  loading: false,
};

export const ping = (state = initialState, action) => {
  switch (action.type) {
    case pingTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case pingTypes.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case pingTypes.error:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
