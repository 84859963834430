import { api } from '../../api';

import { profitTypes } from '../types/profit';

export const fetchProfit =
  ({ start, end, page, limit } = {}) =>
  async (dispatch) => {
    dispatch({ type: profitTypes.loading });

    try {
      const { data } = await api.get('get_domains_profits', {
        params: { start_date: start, end_date: end, page, limit },
      });

      dispatch({ type: profitTypes.success, payload: data });
    } catch (e) {
      dispatch({ type: profitTypes.reject });
    }
  };
