import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lostDomainsFetch } from '../../newStore/actions/lostDomains';

import './managersInfo.scss';

export const ManagersInfo = () => {
  const { loading, list } = useSelector((state) => state.lostDomains);
  const dispatch = useDispatch();

  useEffect(() => dispatch(lostDomainsFetch()), []);

  return (
    <div className='managers-info'>
      {!loading ? (
        <div className='managers-info__table'>
          <div className='managers-info__table-head'>
            <div>Domain</div>
            <div>CreatedAt</div>
          </div>
          <div className='managers-info__table-body'>
            {list?.map((item) => (
              <div className='managers-info__table-item' key={item.ID}>
                <div>{item.domain}</div>
                <div>{item.CreatedAt}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h1>Loading</h1>
      )}
    </div>
  );
};
