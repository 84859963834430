/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import classNames from 'classnames';
import { useEffectOnce } from 'react-use';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { $dates } from '../../store/counts';

import './table.scss';
import CreateModal from '../Modals/CreateModal/CreateModal';
import { DeleteConfirmModal } from '../Modals/DeleteConfirmModal/DeleteConfirmModal';
import { Pagination } from '../Pagination';
import { openModal } from '../../newStore/actions/modal';
import { setEditPage } from '../../newStore/actions/update';
import EditGeo from '../Modals/EditGeo/EditGeo';
import { getPagesCountFetch, pagesFetch, setLimit, setPage } from '../../newStore/actions/pages';
import { pwagsFetch } from '../../newStore/actions/pwag';
import { useSavedValue } from '../../hooks/useSavedValue';

export const Table = () => {
  const { value: activeValue, onChange } = useSavedValue({
    key: 'main_table_active',
    initialValue: false,
  });
  const { list: pwagsList } = useSelector((state) => state.pwags);
  const dispatch = useDispatch();
  const {
    list,
    loading,
    pages: pagesCount,
    count: itemsCount,
    pagination: { page, limit: limitValue },
    search,
  } = useSelector((state) => state.pages);
  const dates = useStore($dates);

  useEffect(() => {
    dispatch(
      pagesFetch({
        date: dates,
        page,
        limit: limitValue,
        active: activeValue,
        search,
      })
    );
  }, [dates, page, limitValue, activeValue, search]);

  useEffectOnce(() => {
    dispatch(pwagsFetch());
  });

  useEffect(
    () => dispatch(getPagesCountFetch({ limit: limitValue, active: activeValue })),
    [limitValue, activeValue]
  );

  const onEdit = (page) => {
    dispatch(setEditPage(page));
    dispatch(openModal('edit'));
  };

  const change = (e) => {
    onChange(e.target.checked);
  };

  return (
    <div className='main-table'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label className='main-table__active'>
          <input
            type='checkbox'
            onChange={change}
            defaultChecked={!!JSON.parse(activeValue)}
            style={{ display: 'none' }}
          />
          <span />
        </label>
        <span style={{ marginLeft: 20 }}>{activeValue ? 'Вкл.' : 'Выкл.'}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Pagination
          setPage={(val) => dispatch(setPage(val))}
          setLimit={(val) => dispatch(setLimit(val))}
          currentPage={page}
          count={pagesCount}
          limitValue={limitValue}
          itemsCount={itemsCount}
          withInfo
        />
        <div style={{ margin: '20px 0 0 20px' }}>
          На странице: {list.list?.length || 0} элементов
        </div>
      </div>
      <div className='main-table__header'>
        <div className='main-table-header-item' />
        <div className='main-table-header-item'>Домен</div>
        <div className='main-table-header-item'>Всего</div>
        <div className='main-table-header-item'>White</div>
        <div className='main-table-header-item'>Black</div>
        <div className='main-table-header-item'>Geos</div>
        <div className='main-table-header-item'>Обязательные querry параметры</div>
        <div className='main-table-header-item'>White</div>
        <div className='main-table-header-item'>
          js_check/
          <br />
          instagram/
          <br />
          facebook
        </div>
        <div className='main-table-header-item'>Pwag name</div>
        <div className='main-table-header-item'>
          anti_cloak/
          <br />
          start_up_ios
        </div>
        <div className='main-table-header-item'>history</div>
        <div className='main-table-header-item'>onelink_id</div>
        <div className='main-table-header-item' style={{ visibility: 'hidden' }} />
        <div className='main-table-header-item' />
      </div>
      <div className='main-table__list'>
        {!loading ? (
          list.list?.map((item, index) => (
            <div className='main-table__item' key={index}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <a
                  className='main-table__link'
                  href={`https://${item.domain}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Open website
                </a>
              </div>
              <div className='main-table__item-domain'>{item.domain}</div>
              <Link className='main-table__item-domain' to={`/current/${item.id}`}>
                {item.bots + item.users}
              </Link>
              <Link className='main-table__item-white' to={`/current/${item.id}`}>
                {item.bots}
              </Link>
              <Link className='main-table__item-black' to={`/current/${item.id}`}>
                <div className='main-table__item-block'>{item.users}</div>
                <div className='main-table__item-block'>{item.lost_count}</div>
                <div className='main-table__item-block'>{item.lost_gp_count}</div>
              </Link>
              <Link
                className='main-table__item-geos'
                style={{ border: item.geos?.length ? '' : '1px solid red' }}
                to={`/current/${item.id}`}
              >
                {item.geos?.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </Link>
              <Link className='main-table__item-keywords' to={`/current/${item.id}`}>
                {item.keywords?.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </Link>
              <Link className='main-table__item-domain' to={`/current/${item.id}`}>
                {item.white}
              </Link>
              <Link className='main-table__item-flags' to={`/current/${item.id}`}>
                <div
                  className={classNames('main-table__item-flags-item', {
                    'main-table__item-flags-item--true': !!item.js_check,
                  })}
                />
                <div
                  className={classNames('main-table__item-flags-item', {
                    'main-table__item-flags-item--true': !!item.facebook,
                  })}
                />
                <div
                  className={classNames('main-table__item-flags-item', {
                    'main-table__item-flags-item--true': !!item.instagram,
                  })}
                />
              </Link>
              <Link
                className='main-table__item-pwag-name'
                to={`/current_pwag/${pwagsList.find((pwag) => item.pwa_id === pwag.ID)?.ID}`}
              >
                {pwagsList.find((pwag) => item.pwa_id === pwag.ID)?.app_name}
              </Link>
              <Link className='main-table__item-flags' to={`/current/${item.id}`}>
                <div
                  className={classNames('main-table__item-flags-item', {
                    'main-table__item-flags-item--true': !!item.anti_cloak,
                  })}
                />
                <div
                  className={classNames('main-table__item-flags-item', {
                    'main-table__item-flags-item--true': !!item.start_up_ios,
                  })}
                />
              </Link>
              <div className={classNames('main-table__item-history')}>
                <div className='main-table__item-history-preview'>{item.history}</div>
                <div className='main-table__item-history-value'>{item.history}</div>
              </div>
              <div className='main-table__item-domain'>{item.onelink_id}</div>
              <div>
                <button className='main-table__item-edit' onClick={() => onEdit(list.pages[index])}>
                  Редактировать
                </button>
              </div>
            </div>
          ))
        ) : (
          <h1>Loading</h1>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Pagination
            setPage={(val) => dispatch(setPage(val))}
            setLimit={(val) => dispatch(setLimit(val))}
            currentPage={page}
            count={pagesCount}
            limitValue={limitValue}
            itemsCount={itemsCount}
            withInfo
          />
        </div>
      </div>

      <CreateModal modalId='edit' />
      <DeleteConfirmModal />
      <EditGeo />
    </div>
  );
};
