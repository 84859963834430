import { priceTypes } from '../types/price';

import { api } from '../../api';

export const fetchPrice = () => async (dispatch) => {
  dispatch({ type: priceTypes.loading });

  try {
    const { data } = await api.get('get_price');

    dispatch({ type: priceTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: priceTypes.reject });
  }
};

export const updatePrice = (value) => async (dispatch) => {
  dispatch({ type: priceTypes.loading });

  try {
    await api.post('update_price', value);

    dispatch({ type: priceTypes.success, payload: value });
  } catch (e) {
    dispatch({ type: priceTypes.reject });
  }
};
