/* eslint-disable no-console */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { api } from '../../api';
import { Button, Checkbox } from '../../baseComponents';
import { Input } from '../../baseComponents/Input/Input';
import { newFtpListFetch } from '../../newStore/actions/ftpList';
import { getFtpOptions } from '../../utils/geos';

import './addGeo.scss';

export const AddGeo = () => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const { ftpsList } = useSelector((state) => state.ftpList);

  const onSubmit = async (data) => {
    data.whites = data.whites.map((item) => item.value);
    try {
      await api.post('/create_geo', data);
      setIsActive(false);
      reset();
    } catch (e) {
      console.log('e',e);
    }
  };

  useEffect(() => dispatch(newFtpListFetch()), []);

  return (
    <div className='add-geo'>
      <Button className='add-geo__toggle' onClick={() => setIsActive((prev) => !prev)}>
        Добавить geo
      </Button>
      <div
        className={classNames('add-geo__dropdown', {
          'add-geo__dropdown--active': isActive,
        })}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type='text'
            {...register('name', { required: true })}
            placeholder='Name'
            error={!!errors.name}
          />
          <Input
            type='text'
            {...register('iso', { required: true })}
            placeholder='Iso'
            error={!!errors.iso}
          />
          <Input type='text' {...register('name_en')} placeholder='Name En' />
          <Controller
            control={control}
            name='white'
            rules={{ required: 'Обязательное поле' }}
            render={({ ref, field }) => (
              <>
                <Select
                  className='create__geos'
                  inputRef={ref}
                  options={getFtpOptions(ftpsList)}
                  type='text'
                  value={getFtpOptions(ftpsList)?.find((item) => item.value === field.value)}
                  onChange={(val) => field.onChange(val.value)}
                  placeholder='white'
                />
                <span style={{ color: 'red ' }}>{errors.white?.message}</span>
              </>
            )}
          />
          <Controller
            control={control}
            name='whites'
            render={({ ref, field }) => (
              <Select
                className='create__geos'
                inputRef={ref}
                isMulti
                options={getFtpOptions(ftpsList)}
                value={getFtpOptions(ftpsList).filter((ftpItem) =>
                  Array.isArray(field.value)
                    ? field.value?.find((item) => item.value === ftpItem.label)
                    : false
                )}
                onChange={(val) => field.onChange(val.map((item) => item))}
                placeholder='whites'
              />
            )}
          />
          <div className='add-geo__dropdown-visible'>
            <Checkbox {...register('visible')} label='Visible' />
            <Checkbox {...register('date_style')} label='Dates style' />
          </div>
          <Button type='submit'>Добавить</Button>
        </form>
      </div>
    </div>
  );
};
