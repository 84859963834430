import { pagesTypes } from '../types/pages';

const initialState = {
  list: [],
  loading: false,
  count: 0,
  pagination: {
    page: 0,
    limit: 25,
  },
};

export const pages = (state = initialState, action) => {
  switch (action.type) {
    case pagesTypes.list.request:
      return {
        ...state,
        loading: true,
      };
    case pagesTypes.list.reject:
      return {
        ...state,
        loading: false,
      };
    case pagesTypes.list.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case pagesTypes.count.set:
      return {
        ...state,
        pages: action.payload.pages,
        count: action.payload.count,
      };
    case pagesTypes.pagination.setPage:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    case pagesTypes.pagination.setLimit:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: action.payload,
        },
      };
    case pagesTypes.search.set:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};
