import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchLanguages, pwagsTranslationsFetch } from '../../newStore/actions/pwag';
import { Button } from '../../baseComponents';

import './pwagTranslates.scss';
import { AddPwagTranslate } from './Add';
import { openModal } from '../../newStore/actions/modal';
import { pwagsTypes } from '../../newStore/types/pwags';

const excludedFields = [
  'UpdatedAt',
  'DeletedAt',
  'install_btn',
  'installing_btn',
  'initialization_btn',
  'open_btn',
  'loading_btn',
  'more_detailed',
  'rating',
  'ratings_and_reviews',
  'additional_information',
  'updated',
  'size',
  'installs',
  'current_version',
  'developer',
  'games',
];

export const PwagTranslates = () => {
  const dispatch = useDispatch();
  const { translates } = useSelector((state) => state.pwags);

  useEffect(() => {
    dispatch(pwagsTranslationsFetch());
    dispatch(fetchLanguages());
  }, []);

  const l = translates.map((item) =>
    Object.keys(item).reduce(
      (acc, key) => (excludedFields.includes(key) ? acc : { ...acc, [key]: item[key] }),
      {}
    )
  );

  const onSetEdit = (id) => {
    dispatch(openModal('add-pwag-translate'));
    dispatch({
      type: pwagsTypes.translates.setEdit,
      payload: translates.find((item) => item.ID === id),
    });
  };

  return (
    <div className='pwag-translates'>
      <Button onClick={() => dispatch(openModal('add-pwag-translate'))}>Создать</Button>
      <div className='pwag-translates__list'>
        <div className='pwag-translates__list-head'>
          <div />
          {Object.values(Object.keys(l[0] || {})).map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
        </div>
        <div className='pwag-translates__list-body'>
          {l.map((a, idx) => (
            <div className='pwag-translates__list-body-item' key={idx}>
              <div>
                <Button style={{ marginLeft: 10 }} size='small' onClick={() => onSetEdit(a.ID)}>
                  Редактировать
                </Button>
              </div>
              {Object.values(a).map((val, index) => {
                if (Array.isArray(val)) {
                  return (
                    <div className='pwag-translates__list-body-counter' key={index}>
                      {val.map((item) => (
                        <div>{item}</div>
                      ))}
                    </div>
                  );
                }

                return <div key={index}>{val}</div>;
              })}
            </div>
          ))}
        </div>
      </div>
      <AddPwagTranslate />
    </div>
  );
};
