import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Button } from '../../baseComponents';
import { Modal } from '../../components';
import { saveOffer } from '../../newStore/actions/offer';

export const Form = ({ id }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset } = useForm();

  const list = useSelector((state) => state.offer.list);

  const editedItem = useMemo(() => list.find((item) => item.ID === id) || {}, [id, list]);

  useEffect(() => {
    if (editedItem) {
      setValue('name', editedItem.name);
      setValue('offer_id', editedItem.offer_id);
    }

    return () => {
      reset();
    };
  }, [editedItem]);

  const onSubmit = (data) => {
    dispatch(saveOffer({ ...editedItem, ...data, offer_id: +data.offer_id }));
  };

  return (
    <Modal id='offer-edit'>
      <form className='offer-edit' onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder='Name' {...register('name')} />
        <Input placeholder='Offer id' type='number' {...register('offer_id', { min: 1 })} />
        <Button type='submit'>Сохранить</Button>
      </form>
    </Modal>
  );
};
