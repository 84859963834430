import { modal } from './modal';
import { update } from './update';
import { errorPage } from './errorPage';
import { ftpList } from './ftpList';
import { domains } from './domains';
import { help } from './help';
import { lostDomains } from './lostDomains';
import { pages } from './pages';
import { streams } from './streams';
import { botText } from './botText';
import { users } from './users';
import { affilates } from './affilates';
import { pwags } from './pwags';
import { locations } from './locations';
import { ping } from './ping';
import { managers } from './managers';
import { prelands } from './prelands';
import { iosApp } from './iosApp';
import { profit } from './profit';
import { price } from './price';
import { offer } from './offer';
import { cloBlock } from './cloBlock';
import { instances } from './instances';
import { whitePage } from './whitepage';
import { onelinks } from './onelink';
import { trafficMethods } from './trafficMethods';
import { specialValues } from './specialValues';

export const reducers = {
  modal,
  update,
  errorPage,
  ftpList,
  domains,
  help,
  lostDomains,
  pages,
  streams,
  botText,
  users,
  affilates,
  pwags,
  locations,
  ping,
  managers,
  prelands,
  iosApp,
  profit,
  price,
  offer,
  cloBlock,
  instances,
  whitePage,
  onelinks,
  trafficMethods,
  specialValues,
};
