/* eslint-disable no-console */
import { getMessages } from '../../utils/requestErrors/getMessage';
import { baseApi } from '../BaseApi';

class UserApi {
  async login(values) {
    try {
      const { data, status } = await baseApi.post('/create_token', values);
      if (status === 200) {
        return Promise.resolve(data);
      }

      return Promise.reject(getMessages('loginRequest', status));
    } catch (e) {
      console.warn(e);
      return Promise.reject(getMessages('common', 500));
    }
  }
}

export const userApi = new UserApi();
