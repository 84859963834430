import { modalTypes } from '../types/modal';

const initialState = {
  show: [],
};

export const modal = (state = initialState, action) => {
  switch (action.type) {
    case modalTypes.open:
      return {
        ...state,
        show: [...state.show, action.payload],
      };
    case modalTypes.close:
      return {
        ...state,
        show: state.show.filter((item) => item !== action.payload),
      };
    default:
      return state;
  }
};
