import isNaN from 'lodash/isNaN';
import React, { useRef } from 'react';
import debounce from 'debounce';

import { DeleteButton, Input } from '../../baseComponents';

import './search.scss';

export const Search = ({ onSearch, minSearchLength = 0 }) => {
  const inputRef = useRef(null);

  const search = debounce((e) => {
    const { value } = e.target;

    if (!isNaN(+value) || value?.length > minSearchLength || value?.length === 0) {
      onSearch(value);
    }
  }, 500);

  const clearSearch = () => {
    inputRef.current.value = '';
    onSearch('');
  };

  return (
    <div className='search'>
      <Input
        className='search__input'
        placeholder='Domain search'
        ref={inputRef}
        onChange={search}
      />
      <DeleteButton className='search__delete' onClick={clearSearch} />
    </div>
  );
};
