/* eslint-disable no-console */
import { api } from '../../api';
import { getCloakGeo } from '../../utils/cloakNum';
import { getToken } from '../../utils/getToken';
import { pwagsTypes } from '../types/pwags';
import { openModal } from './modal';

const urls = {
  0: 'https://dominmater.website',
  1: 'https://pagechocolatenation.xyz',
  2: 'https://domainserverdimon.xyz',
  3: 'https://napravdomennaoldcloaku.xyz',
  5: 'https://latahspool.fun',
  6: 'https://api.tgsocialind.site',
  7: 'https://fallingwaterfallspyer.space',
};

export const upload =
  ({ file, uploadUrl }) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append('myFile', file);

    const url = `${urls[getCloakGeo()]}/${uploadUrl}`;

    const options = {
      method: 'POST',
    };

    options.body = formData;
    try {
      await fetch(url, options);
      dispatch(openModal('success'));
    } catch (e) {
      console.log(e);
    }
  };

export const uploadFile = (file) => async (dispatch) => {
  const formData = new FormData();
  formData.append('myFile', file);

  const options = {
    method: 'POST',
  };

  options.body = formData;
  try {
    await api.post('/create_domains', formData);
    dispatch(openModal('success'));
  } catch (e) {
    console.log(e);
  }
};

export const uploadImage = (file) => async () => {
  const formData = new FormData();
  formData.append('myFile', file);

  const cloakGeo = getCloakGeo();
  const token = getToken();

  const url = `${urls[6]}/upload_image?cloak_geo=${cloakGeo}`;

  const options = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  options.body = formData;

  try {
    const response = await fetch(url, options);
    if (response.status !== 200) {
      return Promise.reject();
    }

    const value = await response.json();

    return value;
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const deleteImage = (id) => async (dispatch) => {
  try {
    const { status } = await api.delete('/delete_image', { params: { id } });

    if (status !== 200) {
      dispatch(openModal('error'));
    }

    dispatch({ type: pwagsTypes.images.delete, payload: id });
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
    return Promise.reject(e);
  }
};
