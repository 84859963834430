import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { LocationsItem } from '../../components/LocationsItem/LocationsItem';
import { fetchLocations, deleteLocation } from '../../newStore/actions/locations';
import { openModal } from '../../newStore/actions/modal';

import { Add } from './Add';
import { DeleteConfirmModal } from './DeleteConfirm';
import './locations.scss';

export const Locations = () => {
  const dispatch = useDispatch();
  const [deletedId, setDeletedId] = useState(null);
  const list = useSelector((state) => state.locations.list || []);
  const loading = useSelector((state) => state.locations.loading);

  useEffectOnce(() => {
    dispatch(fetchLocations());
  });

  const onDelete = () => {
    dispatch(deleteLocation(deletedId));
  };

  const setDelete = (id) => {
    setDeletedId(id);
    dispatch(openModal('delete_confirm--location'));
  };

  return (
    <div className='locations'>
      <div className='locations__head'>
        <Add />
      </div>
      {!loading ? (
        <div className='location__list'>
          {list.map((item) => (
            <LocationsItem item={item} setDeletedId={setDelete} key={item.ID} />
          ))}
        </div>
      ) : (
        <h1>Loading</h1>
      )}
      <DeleteConfirmModal onDelete={onDelete} />
    </div>
  );
};
