import { apiWithoutExtras } from '../../api';

import { managersTypes } from '../types/managers';

export const fetchManagers = () => async (dispatch) => {
  dispatch({ type: managersTypes.loading });

  try {
    const { data } = await apiWithoutExtras.get('/get_managers');

    dispatch({ type: managersTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: managersTypes.reject });
  }
};

export const addManager = (value) => async (dispatch) => {
  try {
    await apiWithoutExtras.post('/create_manager', {
      ...value,
    });

    window.location.reload();
  } catch (e) {
    dispatch({ type: managersTypes.reject });
  }
};

export const editManager = (value) => async (dispatch) => {
  try {
    await apiWithoutExtras.post('/update_manager', {
      ...value,
    });

    window.location.reload();
  } catch (e) {
    dispatch({ type: managersTypes.reject });
  }
};

export const deleteManager = (id) => async (dispatch) => {
  try {
    await apiWithoutExtras.delete(`/delete_manager?id=${id}`);

    window.location.reload();
  } catch (e) {
    dispatch({ type: managersTypes.reject });
  }
};
