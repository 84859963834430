import { api } from '../../api';

import { whitePageTypes } from '../types/whitepage';

export const fetchWhitePage =
  ({ domain }) =>
  async (dispatch) => {
    dispatch({ type: whitePageTypes.loading });
    try {
      const { data } = await api.get(`/get_whitepage`, {
        params: {
          domain,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${domain}${data.type}`);
      document.body.appendChild(link);
      link.click();

      dispatch({ type: whitePageTypes.success, payload: data });
      return data;
    } catch (e) {
      dispatch({ type: whitePageTypes.reject });
    }
  };
