export const pwagsTypes = {
  loading: 'pwas/loading',
  success: 'pwas/success',
  error: 'pwas/error',
  current: {
    loading: 'pwas/current/loading',
    success: 'pwas/current/success',
    error: 'pwas/current/error',
  },
  images: {
    loading: 'pwas/images/loading',
    success: 'pwas/images/success',
    error: 'pwas/images/error',
    add: 'pwas/images/add',
    delete: 'pwas/images/delete',
  },
  languages: {
    loading: 'pwas/languages/loading',
    success: 'pwas/languages/success',
    error: 'pwas/languages/error',
  },
  translates: {
    loading: 'pwas/translates/loading',
    success: 'pwas/translates/success',
    error: 'pwas/translates/error',
    setEdit: 'pwas/setEdit',
    save: 'pwas/save',
  },
};
