/* eslint-disable no-console */
import { api } from '../../api';

import { prelandsTypes } from '../types/prelands';

export const fetchPftps = () => async (dispatch) => {
  try {
    const { data } = await api.get('/get_pftps');

    dispatch({ type: prelandsTypes.setPftps, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const fetchPftpswc = () => async (dispatch) => {
  try {
    const { data } = await api.get('/get_pftpswc');

    dispatch({ type: prelandsTypes.setPftpswc, payload: data });
  } catch (e) {
    console.log(e);
  }
};
