import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPftps, fetchPftpswc } from '../../../newStore/actions/prelands';
import { Modal } from '../../Modal';

import './prelandList.scss';

export const PrelandList = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.prelands.pftpswc);

  useEffect(() => {
    dispatch(fetchPftps());
    dispatch(fetchPftpswc());
  }, []);

  return (
    <Modal className='preland-list' id='pftps'>
      <div className='preland-list__head'>
        <div>ID</div>
        <div>Name</div>
      </div>
      <div className='preland-list__list'>
        {list.map((item) => (
          <div className='preland-list__item'>
            <div>{item.ID}</div>
            <div>{item.name}</div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
