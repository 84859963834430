/* eslint-disable indent */
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import classNames from 'classnames';
import { MDBDataTable } from 'mdbreact';
import { debounce } from 'debounce';

import { forIn } from 'lodash-es';
import {
  $currentPage,
  $currentTablePaging,
  $date,
  $limit,
  currentDomainBlackTableFx,
  currentDomainWhiteTableFx,
  currentPageFetchFx,
  resetCurrentTable,
  setDate,
  setLimit,
  setCurrentTablePage,
  currentDomainAbusersTableFx,
  currentDomainGpAbusersTableFx,
  resetCurrentPage,
  currentDomainGpAbotsTableFx,
  $currentTable,
} from '../../store/currentTable';
import {
  getCurrentTableOptions,
  getOptionsFromLS,
  getColumns,
  prepareCurrentTableRow,
} from '../../utils/table';
import { typesLabels } from '../../utils/create';

import './current.scss';
import { DataFilter, Pagination } from '../../components';
import { CheckDomain } from '../../components/CheckDomain/CheckDomain';
import { Checkbox, CollapsedCell, Input } from '../../baseComponents';
import { useSavedValue } from '../../hooks/useSavedValue';

const excludedFields = ['UpdatedAt', 'DeletedAt', 'history'];

export const Current = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const page = useStore($currentTablePaging);
  const currentPage = useStore($currentPage);
  const currentTableData = useStore($currentTable);
  const limit = useStore($limit);
  const date = useStore($date);
  const [activeTab, setActiveTab] = useState(null);

  const { value: activeCollapsedFields, onChange: changeCollapsedFields } = useSavedValue({
    key: 'collapsed_fields',
    initialValue: false,
  });

  const [set, setSet] = useState(false);

  useEffect(() => {
    currentPageFetchFx(id);

    return () => {
      resetCurrentTable();
      resetCurrentPage();
    };
  }, []);

  const handleClickWhite = () => {
    setActiveTab(0);
  };

  const handleClickBlack = () => {
    setActiveTab(1);
  };

  const handleClickAbusers = () => {
    setActiveTab(2);
  };

  const handleClickAbusersGp = () => {
    setActiveTab(3);
  };

  const handleClickAbots = () => {
    setActiveTab(4);
  };

  useEffect(() => {
    if (currentPage.ID) {
      handleClickBlack();
    }
  }, [currentPage]);

  useEffect(() => {
    switch (activeTab) {
      case 0:
        currentDomainWhiteTableFx({
          domain: currentPage.domain,
          page,
          limit,
          date,
          user,
        });
        break;
      case 1:
        currentDomainBlackTableFx({
          domain: currentPage.domain,
          page,
          limit,
          date,
          user,
        });
        break;
      case 2:
        currentDomainAbusersTableFx({
          domain: currentPage.domain,
          page,
          limit,
          date,
          user,
        });
        break;
      case 3:
        currentDomainGpAbusersTableFx({
          domain: currentPage.domain,
          page,
          limit,
          date,
          user,
        });
        break;
      case 4:
        currentDomainGpAbotsTableFx({
          domain: currentPage.domain,
          page,
          limit,
          date,
          user,
        });
        break;
      default:
    }
  }, [page, limit, date, activeTab, currentPage.domain, user]);

  const onChangeSelect = (val) => {
    localStorage.setItem('options', JSON.stringify(val));
  };

  const currentTableColumns = useMemo(() => {
    const columns = getColumns(currentTableData?.tableData?.list[0]);

    if (!columns?.length) {
      return getOptionsFromLS().map((item) => ({
        field: item.label,
        label: item.label,
      }));
    }

    return columns;
  }, [currentTableData.tableData.list, set]);

  const currentTableRows = useMemo(() => {
    const rows = currentTableData?.tableData?.list?.map((item) => {
      const rowObj = prepareCurrentTableRow(item);

      forIn(rowObj, (value, key) => {
        if (value.length > 5 && Date.parse(value)) {
          return value;
        }

        rowObj[key] =
          activeCollapsedFields && value.length >= 20 ? <CollapsedCell value={value} /> : value;
      });

      return rowObj;
    });

    return rows;
  }, [currentTableData.tableData.list, activeCollapsedFields]);

  const getCellValue = (item) => {
    if (item === 'geos') return currentPage[item].map((geo) => geo.name);
    if (item === 'type' || item === 'type_black') return typesLabels[currentPage[item]];

    return `${currentPage[item]}`;
  };

  const changeUser = debounce((e) => setUser(e.target.value || null), 400);

  return (
    <div className='current'>
      <CheckDomain domain={currentPage.domain} />
      <div className='current__info'>
        {currentPage &&
          Object.keys(currentPage).map((item, index) => {
            if (!excludedFields.includes(item)) {
              return (
                <div className='current__info-item' key={index}>
                  <div className='current__info-item-title'>{item}</div>
                  <div className='current__info-item-value'>{getCellValue(item)}</div>
                </div>
              );
            }

            return <> </>;
          })}
      </div>
      <div className='current__buttons'>
        <button
          className={classNames('current__button', {
            'current__button--active': activeTab === 0,
          })}
          onClick={handleClickWhite}
        >
          Bots
        </button>
        <button
          className={classNames('current__button', {
            'current__button--active': activeTab === 1,
          })}
          onClick={handleClickBlack}
        >
          Users
        </button>
        <button
          className={classNames('current__button current__button--abusers', {
            'current__button--active': activeTab === 2,
          })}
          onClick={handleClickAbusers}
        >
          Abusers
        </button>
        <button
          className={classNames('current__button current__button--abusers', {
            'current__button--active': activeTab === 3,
          })}
          onClick={handleClickAbusersGp}
        >
          Gp_abusers
        </button>
        <button
          className={classNames('current__button current__button--abusers', {
            'current__button--active': activeTab === 4,
          })}
          onClick={handleClickAbots}
        >
          Again_bots
        </button>
        <DataFilter setDate={setDate} />
        <Input placeholder='User' onChange={changeUser} />
        <Select
          className='current__select'
          defaultValue={JSON.parse(localStorage.getItem('options'))}
          onChange={(val) => {
            setSet((prev) => !prev);
            onChangeSelect(val);
          }}
          options={getCurrentTableOptions(currentTableData?.tableData?.list[0])}
          isMulti
          placeholder='Отображаемые поля'
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <Checkbox
          label='Сокращать длинные поля'
          onChange={(e) => changeCollapsedFields(e.target.checked)}
          defaultChecked={!!JSON.parse(activeCollapsedFields)}
        />
      </div>
      {currentTableRows?.length ? (
        <div className='current__limit'>
          <Pagination
            currentPage={page}
            count={currentTableData.tableData.count}
            limitValue={limit}
            setPage={setCurrentTablePage}
            setLimit={setLimit}
            itemsCount={currentTableData.tableData.itemsCount}
            withInfo
          />
        </div>
      ) : (
        ''
      )}
      <MDBDataTable
        data={{ rows: currentTableRows, columns: currentTableColumns }}
        searching={false}
        paging={false}
        noRecordsFoundLabel='Данные не найдены'
      />
      <Pagination
        currentPage={page}
        count={currentTableData.tableData.count}
        limitValue={limit}
        setPage={setCurrentTablePage}
        setLimit={setLimit}
        itemsCount={currentTableData.tableData.itemsCount}
        withInfo
      />
    </div>
  );
};
