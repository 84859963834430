import { botTextTypes } from '../types/botText';

const initialState = {
  loading: false,
  list: [],
};

export const botText = (state = initialState, action) => {
  switch (action.type) {
    case botTextTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case botTextTypes.reject:
      return {
        ...state,
        loading: false,
      };
    case botTextTypes.success:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    default:
      return state;
  }
};
