import { combine, createEffect, createEvent, createStore } from 'effector';
import { api } from '../api';
import { dataFilterOptions } from '../utils/dataFilter';
import { prepareCurrentTableColumn } from '../utils/table';

const $table = createStore({ list: [], count: null });

export const $date = createStore(dataFilterOptions[0].value);

export const $currentPage = createStore({});

export const $currentTablePaging = createStore(0);

export const $limit = createStore(25);

export const setLimit = createEvent();

export const setCurrentTablePage = createEvent();

export const setDate = createEvent();

$currentTablePaging.on(setCurrentTablePage, (_, page) => page);
$limit.on(setLimit, (_, limit) => limit);
$date.on(setDate, (_, date) => date);

export const currentDomainWhiteTableFx = createEffect(
  async ({ domain, page = 0, limit = 25, date = [], user }) => {
    let response;
    if (date[0] && !date[1]) {
      response = await api.get(
        `get_bots?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}`,
        { params: { user } }
      );
    } else if (date[0] && date[1]) {
      response = await api.get(
        `get_bots?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}&end_date=${date[1]}`,
        { params: { user } }
      );
    } else {
      response = await api.get(`get_bots?domain=${domain}&limit=${limit}&page=${page}`, {
        params: { user },
      });
    }
    return {
      list: prepareCurrentTableColumn(response.data.Clicks),
      count: response.data.count / limit,
      itemsCount: response.data.count,
    };
  }
);

export const currentDomainBlackTableFx = createEffect(
  async ({ domain, page = 0, limit = 25, date = [], user }) => {
    let response;
    if (date[0] && !date[1]) {
      response = await api.get(
        `get_users?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}`,
        { params: { user } }
      );
    } else if (date[0] && date[1]) {
      response = await api.get(
        `get_users?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}&end_date=${date[1]}`,
        { params: { user } }
      );
    } else {
      response = await api.get(`get_users?domain=${domain}&limit=${limit}&page=${page}`, {
        params: { user },
      });
    }

    return {
      list: prepareCurrentTableColumn(response.data.Clicks),
      count: response.data.count / limit,
      itemsCount: response.data.count,
    };
  }
);

export const currentDomainAbusersTableFx = createEffect(
  async ({ domain, page = 0, limit = 25, date = [], user }) => {
    let response;
    if (date[0] && !date[1]) {
      response = await api.get(
        `get_abusers?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}`,
        { params: { user } }
      );
    } else if (date[0] && date[1]) {
      response = await api.get(
        `get_abusers?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}&end_date=${date[1]}`,
        { params: { user } }
      );
    } else {
      response = await api.get(`get_abusers?domain=${domain}&limit=${limit}&page=${page}`, {
        params: { user },
      });
    }

    return {
      list: prepareCurrentTableColumn(response.data.Clicks),
      count: response.data.count / limit,
      itemsCount: response.data.count,
    };
  }
);

export const currentDomainGpAbusersTableFx = createEffect(
  async ({ domain, page = 0, limit = 25, date = [], user }) => {
    let response;
    if (date[0] && !date[1]) {
      response = await api.get(
        `get_abusers_gp?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}`,
        { params: { user } }
      );
    } else if (date[0] && date[1]) {
      response = await api.get(
        `get_abusers_gp?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}&end_date=${date[1]}`,
        { params: { user } }
      );
    } else {
      response = await api.get(`get_abusers_gp?domain=${domain}&limit=${limit}&page=${page}`, {
        params: { user },
      });
    }

    return {
      list: prepareCurrentTableColumn(response.data.Clicks),
      count: response.data.count / limit,
      itemsCount: response.data.count,
    };
  }
);

export const currentDomainGpAbotsTableFx = createEffect(
  async ({ domain, page = 0, limit = 25, date = [], user }) => {
    let response;
    if (date[0] && !date[1]) {
      response = await api.get(
        `get_abots?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}`,
        { params: { user } }
      );
    } else if (date[0] && date[1]) {
      response = await api.get(
        `get_abots?domain=${domain}&limit=${limit}&page=${page}&start_date=${date[0]}&end_date=${date[1]}`,
        { params: { user } }
      );
    } else {
      response = await api.get(`get_abots?domain=${domain}&limit=${limit}&page=${page}`, {
        params: { user },
      });
    }

    return {
      list: prepareCurrentTableColumn(response.data.Clicks),
      count: response.data.count / limit,
      itemsCount: response.data.count,
    };
  }
);

export const currentPageFetchFx = createEffect(async (id) => {
  const response = await api.get(`get_page?id=${id}`);
  return response.data;
});

export const $currentTable = combine({
  tableData: $table,

  loading: currentDomainWhiteTableFx.pending,
});

export const resetCurrentTable = createEvent();
export const resetCurrentPage = createEvent();

$table
  .on(currentDomainWhiteTableFx.done, (_, data) => data.result)
  .on(currentDomainBlackTableFx.done, (_, data) => data.result)
  .on(currentDomainAbusersTableFx.done, (_, data) => data.result)
  .on(currentDomainGpAbusersTableFx.done, (_, data) => data.result)
  .on(currentDomainGpAbotsTableFx.done, (_, data) => data.result)
  .reset(resetCurrentTable);

$currentPage.on(currentPageFetchFx.done, (_, { result }) => result).reset(resetCurrentPage);
