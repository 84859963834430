/* eslint-disable no-console */
import { api } from '../../api';
import { ftpListTypes, newFtpListTypes } from '../types/ftpList';
import { openModal } from './modal';

export const ftpListFetch = () => async (dispatch) => {
  try {
    dispatch({ type: ftpListTypes.loading });

    const response = await api.get('/get_ftpswc');
    dispatch({ type: ftpListTypes.success, payload: response.data });
  } catch (e) {
    console.log(e);
    dispatch({ type: ftpListTypes.error });
  }
};

export const newFtpListFetch = () => async (dispatch) => {
  try {
    const response = await api.get('/get_ftps');
    dispatch({ type: newFtpListTypes.success, payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

export const deleteFtp = (id) => async (dispatch) => {
  try {
    await api.delete('/delete_ftp', { params: { id } });

    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};
