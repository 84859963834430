import { getOptionsFromLS } from './table';

export const prepareList = (list) => {
  if (!list) return [];

  const withCounter = list.map((item) => {
    const counterName = Object.keys(item)
      .reduce((acc, key) => (key !== 'affilate' ? `${acc}/${key}` : acc), '')
      .replace('/', '');

    const counterValue = Object.keys(item).reduce(
      (acc, key) => (key !== 'affilate' ? [...acc, item[key]] : acc),
      []
    );

    return {
      ...item.affilate,
      [counterName]: counterValue,
    };
  });

  return withCounter;
};

export const prepareCurrentAffilateList = (list = []) => {
  const excludedList = getOptionsFromLS('affilate_options')?.map((item) => item.value);
  return list.map((listItem) =>
    Object.keys(listItem).reduce(
      (acc, item) => (excludedList.includes(item) ? { ...acc, [item]: listItem[item] } : acc),
      {}
    )
  );
};
