import React from 'react';
import { useSelector } from 'react-redux';
import { closeModal } from '../../../store/modal';

import { Modal } from '../../Modal/Modal';

import './deleteConfirmModal.scss';
import { deletePage } from '../../../newStore/actions/update';

export const DeleteConfirmModal = () => {
  const { page: editPage } = useSelector((state) => state.update);

  return (
    <Modal className='delete-confirm' id='delete_confirm'>
      <h2>Подтверждение действия</h2>
      <div className='delete-confirm__buttons'>
        <button
          className='delete-confirm__button'
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onClick={() => deletePage(editPage.ID)}
        >
          Да
        </button>
        <button className='delete-confirm__button' onClick={() => closeModal('delete_confirm')}>
          Нет
        </button>
      </div>
    </Modal>
  );
};
