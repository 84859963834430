import { specialValuesTypes } from '../types/specialValues';

const initialState = {
  loading: false,
  list: [],
};

export const specialValues = (state = initialState, action) => {
  switch (action.type) {
    case specialValuesTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case specialValuesTypes.error:
      return {
        ...state,
        loading: false,
      };
    case specialValuesTypes.success:
      return {
        ...state,
        list: action.paylaod,
        loading: false,
      };
    default:
      return state;
  }
};
