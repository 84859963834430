/* eslint-disable no-console */

import { api } from '../../api';
import { trafficMethodsTypes } from '../types/trafficMethods';

export const fetchTrafficMethods = () => async (dispatch) => {
  try {
    dispatch({ type: trafficMethodsTypes.loading });
    const { data } = await api.get('get_traffic_methods');
    dispatch({ type: trafficMethodsTypes.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: trafficMethodsTypes.error });
  }
};
