import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { Button } from '../../baseComponents';

import { fetchIosApps } from '../../newStore/actions/iosApp';
import { openModal } from '../../newStore/actions/modal';

import './iosApp.scss';
import { Form } from './Form';

export const IosApp = () => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState();

  const list = useSelector((state) => state.iosApp.list);

  useEffectOnce(() => {
    dispatch(fetchIosApps());
  });

  const openForm = () => dispatch(openModal('ios-app-edit'));

  const openEdit = (e) => {
    openForm();
    setEdit(e);
  };

  const clearEdit = () => setEdit(null);

  return (
    <div className='managers'>
      <Button onClick={openForm}>Создать</Button>
      <div className='managers__list'>
        <div className='managers__list-head'>
          {Object.values(Object.keys(list[0] || {})).map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
          <div />
        </div>
        <div className='managers__list-body'>
          {list.map((a, key) => (
            <div key={key}>
              {Object.values(a).map((item, idx) => (
                <div key={idx}>{item}</div>
              ))}
              <div>
                <Button onClick={() => openEdit(a)}>Редактировать</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Form edit={edit} onHide={clearEdit} />
    </div>
  );
};
