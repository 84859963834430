import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../baseComponents/Input/Input';

import { Modal } from '../../components';
import { updateBotTextFetch } from '../../newStore/actions/botText';

export const Edit = ({ id }) => {
  const dispatch = useDispatch();
  const botTextList = useSelector((state) => state.botText.list);
  const { register, handleSubmit, setValue, reset } = useForm();

  const currentText = useMemo(() => botTextList.find((item) => item.ID === id), [id, botTextList]);

  useEffect(() => setValue('text', currentText?.text), [currentText]);

  const onSubmit = (data) => {
    dispatch(updateBotTextFetch({ ...currentText, ...data }));
    reset();
  };

  return (
    <Modal id='edit-bot-text'>
      <form className='edit-bot-text' onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder='Text' as='textarea' {...register('text', { required: true })} />
        <button type='submit'>Сохранить</button>
      </form>
    </Modal>
  );
};
