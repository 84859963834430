import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import parcsISO from 'date-fns/parseISO';
import { useDispatch, useSelector } from 'react-redux';

import { openModal } from '../../newStore/actions/modal';
import { streamsFetch } from '../../newStore/actions/streams';
import { keyIdOptions } from '../../utils/create';

import { Add } from './Add/Add';
import { DeleteConfirmModal } from './Confirm';

import './streams.scss';

export const Streams = () => {
  const [id, setId] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.streams);

  useEffect(() => {
    dispatch(streamsFetch());
  }, []);

  const onDelete = (deleteId) => {
    setId(deleteId);
    dispatch(openModal('delete_confirm--stream'));
  };
  const onEdit = (editedId) => {
    setCurrentId(editedId);
    dispatch(openModal('addStream'));
  };
  const onCreate = () => dispatch(openModal('addStream'));

  return (
    <div className='streams'>
      {!loading ? (
        <>
          <button className='streams__button' onClick={onCreate}>
            Создать
          </button>
          <div className='streams__head'>
            <div style={{ visibility: 'hidden' }} />
            <div>id</div>
            <div>CreatedAt</div>
            <div>UpdatedAt</div>
            <div>key</div>
            <div>key_id</div>
            <div>link</div>
            <div style={{ visibility: 'hidden' }} />
          </div>
          <div className='streams__list'>
            {list.map((item, index) => (
              <div className='streams__list-item' key={index}>
                <div className='button-cell'>
                  <button className='streams__button' onClick={() => onEdit(item.ID)}>
                    Редактировать
                  </button>
                </div>
                <div>{`${item.ID}`}</div>
                <div>{`${format(parcsISO(item.CreatedAt), 'yyyy-MM-dd:HH:mm')}`}</div>
                <div>{`${format(parcsISO(item.UpdatedAt), 'yyyy-MM-dd:HH:mm')}`}</div>
                <div>{`${item.key}`}</div>
                <div>
                  {`${keyIdOptions.find((optionItem) => optionItem.value === item.key_id)?.label}`}
                </div>
                <div>{`${item.link}`}</div>
                <div className='button-cell'>
                  <button
                    className='streams__button streams__button--delete'
                    onClick={() => onDelete(item.ID)}
                  >
                    Удалить
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <h2>Loading</h2>
      )}
      <DeleteConfirmModal id={id} />
      <Add id={currentId} resetCurrent={() => setCurrentId(null)} />
    </div>
  );
};
