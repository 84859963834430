import { subDays, setHours, setMinutes, setSeconds, addDays } from 'date-fns';
import unix from 'date-fns/getUnixTime';

export const dataFilterOptions = [
  {
    label: 'Сегодня',
    value: [unix(setSeconds(setMinutes(setHours(new Date(), 0), 0), 0))],
  },
  {
    label: 'Вчера',
    value: [
      unix(setSeconds(setMinutes(setHours(subDays(new Date(), 1), 0), 0), 0)),
      unix(setSeconds(setMinutes(setHours(new Date(), 0), 0), 0)),
    ],
  },
  {
    label: 'Позавчера',
    value: [
      unix(setSeconds(setMinutes(setHours(subDays(new Date(), 2), 0), 0), 0)),
      unix(setSeconds(setMinutes(setHours(subDays(new Date(), 1), 0), 0), 0)),
    ],
  },
  {
    label: 'Последние 7 дней',
    value: [
      unix(subDays(new Date(), 7)),
      unix(setSeconds(setMinutes(setHours(addDays(new Date(), 1), 0), 0), 0)),
    ],
  },
  {
    label: 'Интервал времени',
    value: 'time',
  },
  {
    label: 'Интервал дат',
    value: 'date',
  },
  {
    label: 'За всё время',
    value: [],
  },
];
