import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { Button } from '../../../baseComponents';
import { changeDomainGeo } from '../../../newStore/actions/domains';

export const ChangeGeo = ({ domain, zoneId }) => {
  const { control, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => dispatch(changeDomainGeo(domain, data.geo, zoneId));

  const { list, loading } = useSelector((state) => state.instances);

  const geoList = list.map((item) => {
    return {
      value: item.cloak_number,
      label: item.name,
    };
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='geo-form'>
      {!loading && (
        <Controller
          control={control}
          name='geo'
          render={({ ref, field }) => (
            <Select
              className='current-pwag__select'
              inputRef={ref}
              options={geoList}
              type='text'
              value={geoList.find((item) => item.value === field.value)}
              onChange={(val) => field.onChange(val.value)}
              placeholder='geo'
            />
          )}
        />
      )}
      <Button type='submit'>Сохранить</Button>
    </form>
  );
};
