import { api } from '../../api';

import { pingTypes } from '../types/ping';

export const fetchPing =
  ({ page, limit }) =>
  async (dispatch) => {
    try {
      dispatch({ type: pingTypes.loading });
      const { data } = await api.get('/get_pings', { params: { page, limit } });

      dispatch({ type: pingTypes.success, payload: data });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      dispatch({ type: pingTypes.error });
    }
  };
