import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HashLink } from 'react-router-hash-link';
import { AddDomain } from '../../components/AddDomain/AddDomain';
import {
  activateDomainZone,
  changeStatusDomain,
  createDomainZoneBot,
  deactivateDomainZone,
  domainsFetch,
  changeDomainPrice,
  changeZonePrice,
  changeDefaultZonePrice,
} from '../../newStore/actions/domains';
import { DeleteConfirmModal } from '../../components/DeleteDomain/ConfirmDelete';
import { openModal } from '../../newStore/actions/modal';
import { AddZone } from '../../components/AddDomain/AddZone';
import { Button } from '../../baseComponents';
import { AddDomainList } from '../../components/AddDomainList';

import './domains.scss';
import { Domain } from './Domain';

export const Domains = () => {
  const dispatch = useDispatch();
  const [deletedDomain, setDeletedDomain] = useState(null);
  const { list, loading, domainZonesList } = useSelector((state) => state.domains);

  useEffect(() => {
    dispatch(domainsFetch());
  }, []);

  const setDelete = (id) => {
    setDeletedDomain(id);
    dispatch(openModal('delete_confirm--domain'));
  };

  const onChangeZonePrice = (id, data) => dispatch(changeZonePrice(id, data.price));
  const onChangeDomainPrice = (id, data) => dispatch(changeDomainPrice(id, data.price));
  const onChangeDefaultPrice = (initialData) => (_id, data) => {
    dispatch(changeDefaultZonePrice({ ...initialData, default_price: parseFloat(data.price) }));
  };

  return (
    <div className='domains'>
      <div className='domains__inner'>
        <div className='domain-zones'>
          <h3>Доменные зоны</h3>
          <div className='domain-zones__list'>
            {domainZonesList.map((item, idx) => (
              <div className='domain-zones__list-item' key={idx}>
                <HashLink to={`#${item.zone}`} className='domain-zones__list-item-name'>
                  {item.zone}
                </HashLink>
                <Button
                  className='domain-zones__list-item-button'
                  onClick={() => dispatch(activateDomainZone(item.zone))}
                >
                  Activate_zone
                </Button>
                <Button
                  className='domain-zones__list-item-button'
                  onClick={() => dispatch(deactivateDomainZone(item.zone))}
                >
                  Deactivate_zone
                </Button>
                <Button
                  className='domain-zones__list-item-button'
                  onClick={() => dispatch(createDomainZoneBot(item))}
                >
                  Create for bot
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className='domains__header'>
          <AddDomain />
          <AddZone />
          <AddDomainList />
        </div>
        {!loading ? (
          <div className='domains__list'>
            {list.map((item, idx) => (
              <Domain
                item={item}
                setDelete={setDelete}
                onChangeDefaultPrice={onChangeDefaultPrice}
                onChangeZonePrice={onChangeZonePrice}
                onChangeDomainPrice={onChangeDomainPrice}
                changeStatusDomain={changeStatusDomain}
                key={idx}
              />
            ))}
          </div>
        ) : (
          <h2>Loading</h2>
        )}
      </div>
      <DeleteConfirmModal domain={deletedDomain} />
    </div>
  );
};
