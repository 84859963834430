import classNames from 'classnames';
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import { Pagination } from '../../components/Pagination';

import { affilatesClientsFetch } from '../../newStore/actions/affilates';
import { prepareCurrentAffilateList } from '../../utils/affilates';

const tabsUrls = ['get_clients_by_affilate', 'get_cancelled_clients_by_affilate'];

export const CurrentAffilate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { table, tableCount, loading } = useSelector((state) => state.affilates);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [set, setSet] = useState(false);

  useEffect(
    () => dispatch(affilatesClientsFetch(tabsUrls[activeTab], id, page, limit)),
    [id, activeTab, page, limit]
  );

  const l = useMemo(() => prepareCurrentAffilateList(table), [set, table]);

  const selectOptions = useMemo(
    () =>
      Object.keys(table[0] || {}).map((item) => ({
        label: item,
        value: item,
      })),
    [table]
  );

  const onChangeSelect = (val) => {
    localStorage.setItem('affilate_options', JSON.stringify(val));
  };

  return (
    <div className='current-affilate'>
      <div className='current-affilate__tabs'>
        <div
          className={classNames('current-affilate__tabs-item', {
            active: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          Clients
        </div>
        <div
          className={classNames('current-affilate__tabs-item', {
            active: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          Cancelled clients
        </div>
      </div>
      {!loading && (
        <Select
          className='current__select'
          defaultValue={JSON.parse(localStorage.getItem('affilate_options'))}
          onChange={(val) => {
            setSet((prev) => !prev);
            onChangeSelect(val);
          }}
          options={selectOptions}
          isMulti
          placeholder='Отображаемые поля'
        />
      )}
      <Pagination
        setPage={setPage}
        setLimit={setLimit}
        currentPage={page}
        limitValue={limit}
        count={tableCount / limit}
      />
      <div className='affilates__list'>
        <div className='affilates__list-head'>
          {l?.length &&
            Object.values(Object.keys(l[0] || {})).map((item, idx) => <div key={idx}>{item}</div>)}
        </div>
        <div className='affilates__list-body'>
          {l?.map((a, idx) => (
            <div key={idx}>
              {Object.values(a).map((item) => {
                if (Array.isArray(item)) {
                  return (
                    <div className='affilates__list-body-counter'>
                      {item.map((value, index) => (
                        <div key={index}>{value}</div>
                      ))}
                    </div>
                  );
                }

                return <div>{item}</div>;
              })}
            </div>
          ))}
        </div>
      </div>
      <Pagination
        setPage={setPage}
        setLimit={setLimit}
        currentPage={page}
        limitValue={limit}
        count={tableCount / limit}
      />
    </div>
  );
};
