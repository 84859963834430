/* eslint-disable no-console */
import { api } from '../../api';
import { locationTypes } from '../types/locations';
import { closeModal, openModal } from './modal';

export const fetchLocations = () => async (dispatch) => {
  try {
    dispatch({ type: locationTypes.loading });

    const { data } = await api.get('get_locations');

    dispatch({ type: locationTypes.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: locationTypes.error });
  }
};

export const addLocation = (value) => async (dispatch) => {
  try {
    const { data } = await api.post('save_location', value);

    dispatch(openModal('success'));

    dispatch({ type: locationTypes.add, payload: data });
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const saveLocation = (value) => async (dispatch) => {
  try {
    await api.post('save_location', value);

    dispatch(openModal('success'));

    dispatch({ type: locationTypes.save, payload: value });
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const deleteLocation = (id) => async (dispatch) => {
  try {
    await api.delete(`delete_location?id=${id}`);

    dispatch(closeModal('delete_confirm--location'));
    dispatch(openModal('success'));

    dispatch({ type: locationTypes.delete, payload: id });
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};
