import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '../../components/Modal/Modal';
import { deleteManager } from '../../newStore/actions/managers';
import { closeModal } from '../../newStore/actions/modal';

export const DeleteManagerModal = ({ id }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteManager(id));
    dispatch(closeModal('delete_confirm--manager'));
  };

  return (
    <Modal className='delete-confirm' id='delete_confirm--manager'>
      <h2>Подтверждение действия</h2>
      <div className='delete-confirm__buttons'>
        <button
          className='delete-confirm__button'
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onClick={handleDelete}
        >
          Да
        </button>
        <button
          className='delete-confirm__button'
          onClick={() => dispatch(closeModal('delete_confirm--manager'))}
        >
          Нет
        </button>
      </div>
    </Modal>
  );
};
