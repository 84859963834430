import classNames from 'classnames';
import React from 'react';

import './badge.scss';

export const Badge = ({ children, className, size = 'medium', color = 'primary', ...props }) => (
  <div
    className={classNames('badge', className, {
      'badge--primary': color === 'primary',
      'badge--secondary': color === 'secondary',
      'badge--dangerous': color === 'dangerous',

      'badge--small': size === 'small',
      'badge--medium': size === 'medium',
      'badge--big': size === 'big',
    })}
    {...props}
  >
    {children}
  </div>
);
