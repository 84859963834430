import { helpTypes } from '../types/help';

const initialState = {
  list: [],
  loading: false,
};

export const help = (state = initialState, action) => {
  switch (action.type) {
    case helpTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case helpTypes.error:
      return {
        ...state,
        loading: false,
      };
    case helpTypes.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
