import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, Input } from '../../../baseComponents';
import { Modal } from '../../../components/Modal/Modal';
import { createPwag } from '../../../newStore/actions/pwag';

export const Add = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    dispatch(createPwag({ ...data, visible: false }));
  };

  const onReset = () => {
    reset();
  };

  return (
    <Modal id='addPwag' onHide={onReset}>
      <form className='add-form-pwag' onSubmit={handleSubmit(onSubmit)}>
        <Input {...register('app_name')} placeholder='name' />
        <Button type='button' style={{ marginTop: 15 }} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
