import React from 'react';
import './collapsedCell.scss';

export const CollapsedCell = ({ value }) => {
  return (
    <div className='collapsed-cell'>
      <div className='collapsed-cell__preview'>{value}</div>
      <div className='collapsed-cell__value'>{value}</div>
    </div>
  );
};
