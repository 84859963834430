import classNames from 'classnames';
import React from 'react';

import './booleanMarker.scss';

export const BooleanMarker = ({ className, value, invert }) => {
  return (
    <div
      className={classNames('boolean-marker', className, {
        'boolean-marker--truthy': invert ? !value : value,
        'boolean-marker--falsy': invert ? value : !value,
      })}
    />
  );
};
