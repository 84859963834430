/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import axios from 'axios';

import { ls } from '../utils/ls';

export class BaseApi {
  api(method, url, body = null, params = null, headers = {}) {
    const token = ls.get('token');
    /* eslint-disable no-param-reassign */
    if (token && token?.length !== 0) {
      headers = { Authorization: `${token}`, ...headers };
    }

    const axiosConfig = {
      method,
      params,
      data: body,
      url: `https://api.tgsocialind.site${url}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      validateStatus(status) {
        return status >= 200 && status < 501;
      },
    };

    return axios(axiosConfig)
      .then((res) => {
        if (process.env.NODE_ENV !== 'production') console.info('🚀', method, url, res);

        return Promise.resolve(res);
      })
      .catch((error) => {
        console.warn('[API]', JSON.stringify(error));

        return Promise.resolve({
          status: 0,
          type: 'error',
          message: error.message,
        });
      });
  }

  get(...args) {
    return this.api('GET', ...args);
  }

  post(...args) {
    return this.api('POST', ...args);
  }

  put(...args) {
    return this.api('PUT', ...args);
  }

  delete(...args) {
    return this.api('DELETE', ...args);
  }

  patch(...args) {
    return this.api('PATCH', ...args);
  }
}

export const baseApi = new BaseApi();
