import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { Modal } from '../../../components/Modal/Modal';
import { createStream, editStream } from '../../../newStore/actions/streams';
import { geosFetch } from '../../../newStore/actions/update';
import { keyIdOptions } from '../../../utils/create';
import { Button, Input } from '../../../baseComponents';

export const Add = ({ id, resetCurrent }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { list } = useSelector((state) => state.streams);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const currentStream = list.find((item) => item.ID === id);

  const onSubmit = (data) => {
    if (currentStream) {
      dispatch(
        editStream({
          ...currentStream,
          ...data,
        })
      );
    } else {
      dispatch(createStream({ ...data }));
    }

    setError(null);
  };

  useEffect(() => {
    dispatch(geosFetch());
  }, []);

  useEffect(() => {
    if (currentStream) {
      setValue('ID', currentStream.ID);
      setValue('key', currentStream.key);
      setValue('link', currentStream.link);
      setValue('key_id', currentStream.key_id);
    }
  }, [currentStream]);

  const onReset = () => {
    resetCurrent();
    reset();
    setError(null);
  };

  return (
    <Modal id='addStream' onHide={onReset}>
      <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
      <form className='add-form' onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder='key' {...register('key')} />
        <Input placeholder='link' {...register('link')} />
        <label style={{ marginTop: 'auto' }}>key_id</label>
        <Controller
          control={control}
          name='key_id'
          rules={{ required: 'Обязательное поле' }}
          render={({ ref, field }) => (
            <>
              <Select
                className='create__geos'
                inputRef={ref}
                options={keyIdOptions}
                type='text'
                menuPlacement='top'
                value={keyIdOptions.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                placeholder='type'
              />
              <span style={{ color: 'red' }}>{errors.key_id?.message}</span>
            </>
          )}
        />
        <Button className='add-form__button' type='submit' style={{ marginTop: 15 }}>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
