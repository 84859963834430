import React from 'react';

import { Filters, Table } from '../../components';
import FtpList from '../../components/Modals/FtpList/FtpList';
import { PrelandList } from '../../components/Modals/PrelandList/PrelandList';

export const Main = () => (
  <div className='main'>
    <Filters />
    <Table />
    <FtpList />
    <PrelandList />
  </div>
);
