/* eslint-disable no-console */
/* eslint-disable indent */
import { api } from '../../api';
import { pagesTypes } from '../types/pages';
import { openModal } from './modal';

export const pagesFetch =
  ({ date = [], page = 0, limit = 25, active, search }) =>
  async (dispatch) => {
    let pages;

    dispatch({ type: pagesTypes.list.request });

    try {
      if (date[0] && !date[1]) {
        pages = await api.get(`get_pages?limit=${limit}&page=${page}&start_date=${date[0]}`, {
          params: { active: active || undefined, domain: search },
        });
      } else if (date[0] && date[1]) {
        pages = await api.get(
          `get_pages?limit=${limit}&page=${page}&start_date=${date[0]}&end_date=${date[1]}`,
          { params: { active: active || undefined, domain: search } }
        );
      } else {
        pages = await api.get(`get_pages?limit=${limit}&page=${page}`, {
          params: { active: active || undefined, domain: search },
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({ type: pagesTypes.list.reject });
    }

    dispatch({
      type: pagesTypes.list.success,
      payload: {
        pages: pages?.data,
        list: pages?.data?.map((item, index) => ({
          ...item,
          domain: pages.data[index]?.domain,
          bots: item.white_count,
          users: item.black_count,
          geos: pages.data[index].geos?.map((geo) => geo.name),
          id: pages.data[index]?.ID,
          lost_count: item.lost_count,
          keywords: item.keywords,
          white: `(${item.type}) ${item.white_page}`,
          pages,
        })),
      },
    });
  };

export const getPagesCountFetch =
  ({ limit = 25, active }) =>
  async (dispatch) => {
    try {
      const response = await api.get('get_pages_count', {
        params: { active: active || undefined },
      });

      dispatch({
        type: pagesTypes.count.set,
        payload: { pages: response.data / limit, count: response.data },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const returnPage = (id) => async (dispatch) => {
  try {
    await api.post(`return_page?id=${id}`);

    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const setLimit = (limit) => ({
  type: pagesTypes.pagination.setLimit,
  payload: limit,
});

export const setPage = (page) => ({
  type: pagesTypes.pagination.setPage,
  payload: page,
});
