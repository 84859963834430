import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import uniqBy from 'lodash/uniqBy';

import { Button, FormColorPicker, Input } from '../../baseComponents';
import { Checkbox } from '../../baseComponents/Checkbox/Checkbox';
import {
  currentPwagFetch,
  editPwag,
  fetchLanguages,
  pwagsImagesFetch,
} from '../../newStore/actions/pwag';
import { FieldArray } from './FieldArray';
import { geosFetch } from '../../newStore/actions/update';

import './currentPwag.scss';
import { Image } from './Image';
import { getLanguageOptions } from '../../utils/pwag';
import { prepareGeosOptions } from '../../utils/create';
import { fetchPftps } from '../../newStore/actions/prelands';
import { fetchIosApps } from '../../newStore/actions/iosApp';

export const CurrentPwag = () => {
  const { id } = useParams();
  const [imagesOpen, setImagesOpen] = useState(false);
  const { geos } = useSelector((state) => state.update);
  const { register, handleSubmit, setValue, control } = useForm();
  const dispatch = useDispatch();
  const { current, loading, images, languages } = useSelector((state) => state.pwags);
  const iosApps = useSelector((state) => state.iosApp.list);
  const pftps = useSelector((state) => state.prelands.pftps);
  const [language, setLanguage] = useState(languages[0]?.iso);
  const { list } = useSelector((state) => state.instances);
  const [deleteNamesId, setDeleteNamesId] = useState([]);
  const [deleteDescriptionsId, setDeleteDescriptionsId] = useState([]);
  const [deleteDevelopersId, setDeleteDevelopersId] = useState([]);
  const [deleteCommentsId, setDeleteCommentsId] = useState([]);

  useEffect(() => {
    dispatch(geosFetch());
    dispatch(fetchLanguages());
    dispatch(fetchIosApps());
    dispatch(fetchPftps());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(currentPwagFetch(id));
    }
  }, [id]);

  useEffect(() => {
    if (!images) {
      dispatch(pwagsImagesFetch());
    }
  }, [images]);

  useEffect(() => {
    if (!language) {
      setLanguage(languages[0]?.iso);
    }
  }, [languages, language]);

  useEffect(() => {
    if (current) {
      setValue('visible', current.visible);
      setValue('description', current.description);
      setValue('last_text', current.last_text);
      setValue(
        'names',
        current.names?.filter((item) => item.language === language)
      );
      setValue(
        'descriptions',
        current.descriptions?.filter((item) => item.language === language)
      );
      setValue('app_name', current.app_name);
      setValue('pwd', current.pwd);
      setValue('priority', current.priority);
      setValue(
        'comments',
        current.comments
          .filter((item) => item.language === language)
          .sort((a, b) => b.priority - a.priority)
      );
      setValue('icon_id', current.icon_id);
      setValue('gallery_id', current.gallery_id);
      setValue('geos', current.geos);
      setValue('description', current.description);
      setValue('one_signal_key', current.one_signal_key);
      setValue('rating', current.rating);
      setValue(
        'developers',
        current.developers?.filter((item) => item.language === language) || []
      );
      setValue('theme_color', current.theme_color);
      setValue('background_color', current.background_color);
      setValue('joxy', current.joxy);
      setValue('default_pid', current.default_pid);
      setValue('default_ios', current.default_ios);
      setValue('loader_text_color', current.loader_text_color);
      current?.one_signal_keys.map((item) => {
        return setValue(`onesignal_key-${item.ID}`, item.onesignal_key);
      });
    }
  }, [current, language]);

  const onSubmit = (data) => {
    data.priority = +data.priority;
    data.comments = uniqBy([
      ...current.comments.filter((item) => !deleteCommentsId.includes(item.ID)),
      ...data.comments.map((item, idx) => ({
        ...item,
        like_c: +item.like_c,
        priority: data.comments.length - idx,
      })),
    ]);
    data.descriptions = uniqBy([
      ...current.descriptions.filter((item) => !deleteDescriptionsId.includes(item.ID)),
      ...data.descriptions,
    ]);
    data.names = uniqBy([
      ...current.names.filter((item) => !deleteNamesId.includes(item.ID)),
      ...data.names,
    ]);
    data.developers = uniqBy([
      ...current.developers.filter((item) => !deleteDevelopersId.includes(item.ID)),
      ...data.developers,
    ]);
    data.one_signal_keys = uniqBy(
      current.one_signal_keys.map((item) => {
        return {
          ...item,
          onesignal_key: data[`onesignal_key-${item.ID}`],
        };
      })
    );
    current.one_signal_keys.map((item) => {
      return delete data[`onesignal_key-${item.ID}`];
    });

    dispatch(
      editPwag({
        ...current,
        ...data,
      })
    );

    setDeleteNamesId([]);
    setDeleteDescriptionsId([]);
    setDeleteDevelopersId([]);
    setDeleteCommentsId([]);
  };

  const languagesOptions = useMemo(() => getLanguageOptions(languages), [languages]);

  const iosAppOptions = useMemo(
    () =>
      iosApps?.map((item) => ({
        value: item.ID,
        label: item.app_name,
      })),
    [iosApps]
  );

  const pftpsOptions = useMemo(
    () =>
      pftps?.map((item) => ({
        value: item.ID,
        label: item.name,
      })),
    [pftps]
  );

  const Avatar = React.memo(({ name }) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Image
          className='avatar'
          title='Avatar'
          onChange={field.onChange}
          value={field.value}
          images={images}
          withDelete
        />
      )}
    />
  ));

  return loading ? (
    <h1>Loading</h1>
  ) : (
    current && (
      <form className='current-pwag' onSubmit={handleSubmit(onSubmit)}>
        <div className='current-pwag__head'>
          <Button type='submit'>Сохранить</Button>
          <Select
            className='current-pwag__language'
            options={languagesOptions}
            defaultValue={languagesOptions.find((item) => item.label === 'США')}
            onChange={(val) => setLanguage(val.value)}
          />
        </div>
        <Checkbox className='current-pwag__checkbox' label='Visible' {...register('visible')} />
        {imagesOpen && (
          <div className='current-pwag__array-fields current-pwag__array-fields--vertical'>
            <Controller
              name='icon_id'
              control={control}
              render={({ field }) => (
                <Image
                  className='current-pwag__icon'
                  title='Icon'
                  onChange={field.onChange}
                  value={field.value}
                  images={images}
                />
              )}
            />
            <Controller
              name='gallery_id'
              control={control}
              render={({ field }) => (
                <Image
                  title='Gallery'
                  className='current-pwag__gallery'
                  onChange={field.onChange}
                  value={field.value}
                  images={images}
                />
              )}
            />
          </div>
        )}
        <Button
          className='current-pwag__toggle-images'
          onClick={() => setImagesOpen((prev) => !prev)}
        >
          {imagesOpen ? 'Скрыть картинки' : 'Показать картинки'}
        </Button>
        <div className='current-pwag__controls'>
          <Input {...register('app_name')} placeholder='App_name' />
          <Input {...register('pwd')} placeholder='Pwd' />
          <Input {...register('priority')} placeholder='Priority' />
          <Input {...register('rating')} placeholder='Rating' />
          <Input {...register('joxy')} placeholder='Joxy' />
          <FormColorPicker name='theme_color' label='Theme Color' control={control} />
          <FormColorPicker name='background_color' label='Background color' control={control} />
          <Input as='input' {...register('one_signal_key')} placeholder='One_signal_key' />
          <Input as='textarea' {...register('last_text')} placeholder='Last_text' />
          <Input as='textarea' {...register('description')} placeholder='Description' />
          <FormColorPicker
            name='loader_text_color'
            label='Цвет текста загрузки «Loading»'
            control={control}
          />
          <Controller
            control={control}
            name='geos'
            render={({ ref, field }) => (
              <Select
                className='current-pwag__select'
                placeholder='Geos'
                inputRef={ref}
                isMulti
                options={prepareGeosOptions(geos)}
                value={prepareGeosOptions(geos)
                  .filter((geoItem) =>
                    Array.isArray(field.value)
                      ? field.value?.find((item) => item.name === geoItem.label)
                      : null
                  )
                  .filter(Boolean)}
                onChange={(val) => field.onChange(val.map((item) => item.value))}
              />
            )}
          />
          <Controller
            control={control}
            name='default_ios'
            render={({ ref, field }) => (
              <Select
                className='current-pwag__select'
                inputRef={ref}
                options={iosAppOptions}
                type='text'
                value={iosAppOptions.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                placeholder='default_ios'
              />
            )}
          />
          <Controller
            control={control}
            name='default_pid'
            render={({ ref, field }) => (
              <Select
                className='current-pwag__select'
                inputRef={ref}
                options={pftpsOptions}
                type='text'
                value={pftpsOptions.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                placeholder='default_pid'
              />
            )}
          />
        </div>
        <h3 className='current-pwag__title'>One signal keys</h3>
        <div className='current-pwag__one-signal'>
          {current?.one_signal_keys.map((item, index) => (
            <div key={index} className='current-pwag__one-signal-row'>
              <h4 className='current-pwag__one-signal-row-name'>
                {list &&
                  list.length > 0 &&
                  list.filter((filterItem) => {
                    return filterItem.cloak_number === item.cloak_number;
                  })[0]?.name}
              </h4>
              <p className='current-pwag__one-signal-title'>{item.web_site}</p>
              <Input {...register(`onesignal_key-${item.ID}`)} placeholder='onesignal_key' />
            </div>
          ))}
        </div>
        <div className='current-pwag__array-fields'>
          <FieldArray
            control={control}
            register={register}
            setDeleteId={setDeleteNamesId}
            deleteId={deleteNamesId}
            lang={language}
            name='names'
            buttonText='Добавить имя'
            fieldsList={[
              { value: 'text', placeholder: 'Name', as: 'input' },
              { value: 'short_name', placeholder: 'Short name', as: 'input' },
              { value: 'long_name', placeholder: 'Long name', as: 'input' },
            ]}
            defaultObj={{ text: '', short_name: '', long_name: '' }}
          />
          <FieldArray
            control={control}
            register={register}
            setDeleteId={setDeleteDevelopersId}
            deleteId={deleteDevelopersId}
            lang={language}
            name='developers'
            buttonText='Добавить developer'
            fieldsList={[{ value: 'text', placeholder: 'Developer', as: 'input' }]}
            defaultObj={{ text: '' }}
          />
          <FieldArray
            control={control}
            register={register}
            setDeleteId={setDeleteDescriptionsId}
            deleteId={deleteDescriptionsId}
            lang={language}
            name='descriptions'
            buttonText='Добавить описание'
            fieldsList={[{ value: 'text', placeholder: 'Description', as: 'textarea' }]}
            defaultObj={{ text: '' }}
          />
        </div>
        <div className='current-pwag__array-fields'>
          <FieldArray
            control={control}
            register={register}
            setDeleteId={setDeleteCommentsId}
            deleteId={deleteCommentsId}
            lang={language}
            name='comments'
            buttonText='Добавить комментарий'
            withPriority
            fieldsList={[
              { value: 'avatar_id', as: Avatar },
              { value: 'name', placeholder: 'Name', as: 'input' },
              { value: 'text', placeholder: 'Comment', as: 'textarea' },
              { value: 'like_c', placeholder: 'like_c', as: 'number' },
            ]}
            defaultObj={{ name: '', text: '', avatar_id: 0, like_c: 0 }}
          />
        </div>
      </form>
    )
  );
};
