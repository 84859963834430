import { onelinkGeosTypes, onelinkTypes } from '../types/onelink';

const initialState = {
  loading: false,
  list: [{ id: 1 }],
  geos: [],
};

export const onelinks = (state = initialState, action) => {
  switch (action.type) {
    case onelinkTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case onelinkTypes.error:
      return {
        ...state,
        loading: false,
      };
    case onelinkTypes.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case onelinkGeosTypes.success:
      return {
        ...state,
        geos: action.payload,
      };
    default:
      return state;
  }
};
