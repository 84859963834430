export const getCloakNum = () => {
  const cloakNum = localStorage.getItem('cloak_num');

  if (!cloakNum) {
    localStorage.setItem('cloak_num', 0);
  }

  return cloakNum;
};

export const setCloakNum = (num) => localStorage.setItem('cloak_num', num);

export const cloakNumOptions = [
  { label: 'Клобот', value: '0' },
  { label: 'Фродобот', value: '1' },
  { label: 'Анозербот', value: '2' },
  { label: 'Affstar', value: '9' },
  { label: 'Бот1', value: '10' },
  { label: '4Bot', value: '11' },
  { label: 'Gp_card_bot', value: '12' },
  { label: 'Бот Артёма', value: '13' },
  { label: 'Криптобот', value: '14' },
  { label: 'Circulo Apotas', value: '15'}
];

export const getCloakGeo = () => {
  const cloakGeo = localStorage.getItem('cloak_geo');

  if (!cloakGeo) {
    localStorage.setItem('cloak_geo', 0);
  }

  return cloakGeo;
};

export const cloakGeoOptions = [
  { label: 'Россия', value: 0 },
  { label: 'Германия', value: 2 },
  { label: 'Новая Россия', value: 5 },
  { label: 'Нулевая клоака', value: 3 },
];

export const setCloakGeo = (geo) => localStorage.setItem('cloak_geo', geo);

export const getImagePath = (name) =>
  `https://api.tgsocialind.site/get_image_by_name/${name}?cloak_num=${getCloakNum()}&cloak_geo=${getCloakGeo()}`;
