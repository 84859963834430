/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { api } from '../../../api';
import { closeModal, openModal } from '../../../newStore/actions/modal';
import { Input } from '../../../baseComponents/Input/Input';
import { prepareKeywordsOptions } from '../../../utils/create';

const Form = ({ geo, reset }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (geo) {
      setValue('name', geo.name);
      setValue('iso', geo.iso);
      setValue('white', geo.white);
      setValue('visible', geo.visible);
      setValue('whites', prepareKeywordsOptions(geo.whites));
    }
  }, [geo]);

  const onSubmit = async (data) => {
    data = { ...geo, ...data };
    delete data.history;
    delete data.CreatedAt;
    delete data.DeletedAt;
    delete data.UpdatedAt;
    data.whites = data.whites.map((item) => item.value);

    try {
      await api.post('/update_geo', data);
      dispatch(closeModal('editGeo'));
      dispatch(openModal('success'));
      reset();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <button className='back' onClick={reset}>
        Назад
      </button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type='text'
          {...register('name', { required: true })}
          placeholder='Name'
          error={!!errors.name}
        />
        <Input
          type='text'
          {...register('iso', { required: true })}
          placeholder='Iso'
          error={!!errors.iso}
        />
        <Input type='text' {...register('name_en')} placeholder='Name En' />
        <label style={{ marginTop: 15 }}>Visible</label>
        <input type='checkbox' {...register('visible')} style={{ margin: '0 auto' }} />
        <label style={{ marginTop: 15 }}>Date style</label>
        <input type='checkbox' {...register('date_style')} style={{ margin: '0 auto' }} />
        <button type='submit'>Сохранить</button>
      </form>
    </>
  );
};

export default Form;
