import React from 'react';
import Select from 'react-select';
import Paging from 'react-paginate';
import classNames from 'classnames';

import { limitSelectOptions } from '../../utils/table';

import './pagination.scss';

export const Pagination = ({
  setPage,
  setLimit,
  currentPage,
  limitValue,
  count,
  withInfo,
  itemsCount,
  className,
}) => (
  <div className={classNames('pagination', className)}>
    <Paging
      containerClassName='pagination'
      forcePage={currentPage}
      pageCount={count}
      onPageChange={(val) => setPage(val.selected)}
    />
    <Select
      className='pagination__limit'
      value={limitSelectOptions.find((item) => item.value === limitValue)}
      onChange={(val) => setLimit(val.value)}
      options={limitSelectOptions}
    />
    {withInfo && (
      <span>
        {`${1 + limitValue * (currentPage + 1) - limitValue} - ${
          limitValue * (currentPage + 1)
        } из ${itemsCount}`}
      </span>
    )}
  </div>
);
