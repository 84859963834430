import { prelandsTypes } from '../types/prelands';

const initialState = {
  pftps: [],
  pftpswc: [],
};

export const prelands = (state = initialState, action) => {
  switch (action.type) {
    case prelandsTypes.setPftps:
      return {
        ...state,
        pftps: action.payload,
      };

    case prelandsTypes.setPftpswc:
      return {
        ...state,
        pftpswc: action.payload,
      };

    default:
      return state;
  }
};
