import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Input } from '../../baseComponents';

export const ChangePrice = ({ price, id, isDomain, submit }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => setValue('price', price), [price]);

  const onSubmit = (data) => submit(id, data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        placeholder={isDomain ? '' : 'Price'}
        {...register('price', { required: true })}
        error={!!errors.price}
      />
      <Button type='submit' className='domains__button' style={{ marginLeft: 15 }}>
        {isDomain ? 'Сохранить' : 'Обновить цену'}
      </Button>
    </form>
  );
};
