import classNames from 'classnames';
import React from 'react';

import './deleteButton.scss';
import { ReactComponent as DeleteIcon } from './assets/trash.svg';

export const DeleteButton = ({ className, type = 'button', onClick, text }) => (
  <button className={classNames('delete-button', className)} type={type} onClick={onClick}>
    <DeleteIcon />
    {!!text && <span className='delete-button__text'>{text}</span>}
  </button>
);
