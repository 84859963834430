import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../baseComponents';
import { Modal } from '../../components/Modal/Modal';
import { closeModal } from '../../newStore/actions/modal';

export const DeleteConfirmModal = ({ onDelete }) => {
  const dispatch = useDispatch();

  return (
    <Modal className='delete-confirm' id='delete_confirm--location'>
      <h2>Подтверждение действия</h2>
      <div className='delete-confirm__buttons'>
        <Button
          color='dangers'
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onClick={onDelete}
        >
          Да
        </Button>
        <Button onClick={() => dispatch(closeModal('delete_confirm'))}>Нет</Button>
      </div>
    </Modal>
  );
};
