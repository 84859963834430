import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { botTextFetch } from '../../newStore/actions/botText';
import { openModal } from '../../newStore/actions/modal';

import './botText.scss';
import { Edit } from './Edit';

export const BotText = () => {
  const dispatch = useDispatch();
  const [editedId, setEditedId] = useState(null);
  const { list, loading } = useSelector((state) => state.botText);

  useEffect(() => dispatch(botTextFetch()), []);

  const handleOpenEdit = (id) => {
    setEditedId(id);
    dispatch(openModal('edit-bot-text'));
  };

  return (
    <div className='bot-text'>
      <div className='bot-text__table'>
        <div className='bot-text__table-head'>
          <div>Place</div>
          <div>Text</div>
          <div>Description</div>
          <div style={{ visibility: 'hidden' }} />
        </div>
        {!loading ? (
          <div className='bot-text__table-body'>
            {list?.map((item) => (
              <div className='bot-text__table-item' key={item.ID}>
                <div>{item.place}</div>
                <div>{item.text}</div>
                <div>{item.description}</div>
                <div className='bot-text__table-button'>
                  <button onClick={() => handleOpenEdit(item.ID)}>Редактировать</button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <h2>Loading</h2>
        )}
      </div>
      <Edit id={editedId} />
    </div>
  );
};
