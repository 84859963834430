import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { openModal } from '../../../newStore/actions/modal';
import { createFetch, editFetch, geosFetch, resetPage } from '../../../newStore/actions/update';
import {
  getPwagOptions,
  prepareCreateData,
  prepareGeosOptions,
  prepareKeywordsOptions,
  typesOptions,
} from '../../../utils/create';
import { Modal } from '../../Modal/Modal';
import { Button, Checkbox, Input } from '../../../baseComponents';

import './createModal.scss';
import { ReturnUrlConfirm } from '../ReturnUrlConfirm';
import { Pixels } from './Pixels';
import { fetchWhitePage } from '../../../newStore/actions/whitepage';
import { fetchTrafficMethods } from '../../../newStore/actions/trafficMethods';
import { onelinksFetch } from '../../../newStore/actions/onelink';
import { getBlackTypesOptions } from '../../../utils/getBlackTypesOption';
import { getOnelinksOptions } from '../../../utils/getOnelinksOptions';

const CreateModal = ({ modalId = 'create' }) => {
  const dispatch = useDispatch();
  const prelands = useSelector((state) => state.prelands.pftps);
  const { list: pwagsList } = useSelector((state) => state.pwags);
  const { page: editPage, geos } = useSelector((state) => state.update);
  const { list: trafficMethods } = useSelector((state) => state.trafficMethods);
  const { list: oneLinks } = useSelector((state) => state.onelinks);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const keys = watch('keywords');

  // const blackPageValidator = (v) => {
  //   if ((typeBlack === 4 || typeBlack === 5) && !v.startsWith("https://")) {
  //     return false;
  //   }

  //   return true;
  // };

  useEffect(() => {
    if (modalId === 'create') {
      dispatch(geosFetch());
    }
  }, []);

  useEffect(() => {
    dispatch(fetchTrafficMethods());
    dispatch(onelinksFetch());
  }, []);

  useEffect(() => {
    setValue('domain', editPage.domain);
    setValue('black_page', editPage.black_page);
    setValue('white_page', editPage.white_page);
    setValue('black_count', editPage.black_count);
    setValue('bad_count', editPage.bad_count);
    setValue('type', editPage.type);
    setValue('type_black', editPage.type_black);
    setValue('js_check', editPage.js_check);
    setValue('facebook', editPage.facebook);
    setValue('instagram', editPage.instagram);
    setValue('geos', editPage.geos || []);
    setValue('pwa_id', editPage.pwa_id);
    setValue('accept_code', editPage.accept_code);
    setValue('anti_cloak', editPage.anti_cloak);
    setValue('start_up_ios', editPage.start_up_ios);
    setValue('tt_pixel', editPage.tt_pixel);
    setValue('preland_id', editPage.preland_id);
    setValue('ban_desctop', editPage.ban_desctop);
    setValue('onelink_id', editPage.onelink_id);
    setValue(
      'tt_pixels',
      Array.isArray(editPage.tt_pixels) ? editPage.tt_pixels.map((item) => ({ value: item })) : []
    );
    setValue('keywords', prepareKeywordsOptions(editPage.keywords) || []);
  }, [editPage]);

  const handleClick = () => {
    dispatch(fetchWhitePage({ domain: editPage.domain }));
  };

  const onSubmit = (data) => {
    data.keywords = data.keywords.map((item) => item.value);
    data.instagram = JSON.parse(data.instagram);
    data.facebook = JSON.parse(data.facebook);
    data.js_check = JSON.parse(data.js_check);
    data.tt_pixels = data.tt_pixels.map((item) => item.value);

    delete data.black_count;
    delete data.lost_gp_count;

    if (!editPage.ID) {
      dispatch(createFetch(prepareCreateData({ ...data })));
    } else {
      dispatch(editFetch(prepareCreateData({ ...data, ID: editPage.ID })));
    }
    reset();
    dispatch(resetPage());
  };

  const onCloseModal = () => {
    dispatch(resetPage());
    reset();
  };

  const pwagOptions = [{ label: 'Не выбрано', value: 0 }, ...getPwagOptions(pwagsList)];

  const prelandsOptions = useMemo(
    () => [
      { label: 'Не выбрано', value: 0 },
      ...prelands.map((item) => ({
        label: item.name,
        value: item.ID,
      })),
    ],
    [prelands]
  );

  const blackTypesOptions = useMemo(() => {
    return getBlackTypesOptions(trafficMethods);
  }, [trafficMethods]);

  const oneLinksOption = useMemo(() => {
    return getOnelinksOptions(oneLinks);
  }, [oneLinks]);

  return (
    <>
      <Modal id={modalId} onHide={onCloseModal}>
        <form
          className='create'
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
        >
          <div className='create__row'>
            <Input
              placeholder='Domain'
              error={errors.domain}
              {...register('domain', { required: 'Обязательное поле' })}
            />
            <Input
              placeholder='white_page'
              error={errors.white_page}
              {...register('white_page', { required: 'Обязательное поле' })}
            />
          </div>
          <div className='create__row'>
            <Input placeholder='black_page' error={errors.black_page} {...register('black_page')} />
            <Input
              placeholder='bad_count'
              error={errors.bad_count}
              {...register('bad_count', { required: 'Обязательное поле' })}
            />
          </div>
          <div className='create__row'>
            <Input
              placeholder='accept_code'
              error={errors.accept_code}
              {...register('accept_code')}
            />
            <Input placeholder='tt_pixel' error={errors.accept_code} {...register('tt_pixel')} />
          </div>
          <div className='create__row'>
            <Checkbox {...register('js_check')} label='js_check' />
            <Checkbox {...register('facebook')} label='facebook' />
            <Checkbox {...register('instagram')} label='instagram' />
          </div>
          <label className='input-label' htmlFor='pwa_id'>
            Pwag
          </label>
          <Controller
            control={control}
            name='pwa_id'
            render={({ ref, field }) => (
              <Select
                className='create__geos'
                inputRef={ref}
                options={pwagOptions}
                type='text'
                value={pwagOptions.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                placeholder='pwag'
              />
            )}
          />
          <label className='input-label' htmlFor='type'>
            Type
          </label>
          <Controller
            control={control}
            name='type'
            render={({ ref, field }) => (
              <Select
                className='create__geos'
                inputRef={ref}
                options={typesOptions}
                type='text'
                value={typesOptions.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                placeholder='type'
              />
            )}
          />
          <label className='input-label' htmlFor='type_black'>
            Type_black
          </label>
          <Controller
            control={control}
            name='type_black'
            render={({ ref, field }) => (
              <Select
                className='create__geos'
                menuPlacement='top'
                inputRef={ref}
                options={blackTypesOptions}
                type='text'
                value={blackTypesOptions.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                placeholder='type_black'
              />
            )}
          />
          <label className='input-label' htmlFor='geos'>
            Geos
          </label>
          <Controller
            control={control}
            name='geos'
            render={({ ref, field }) => (
              <Select
                className='create__geos'
                inputRef={ref}
                menuPlacement='top'
                isMulti
                options={prepareGeosOptions(geos)}
                value={prepareGeosOptions(geos).filter((geoItem) =>
                  Array.isArray(field.value)
                    ? field.value?.find((item) => item.name === geoItem.label)
                    : []
                )}
                onChange={(val) => field.onChange(val.map((item) => item.value))}
              />
            )}
          />
          <label className='input-label' htmlFor='prelands'>
            Preland id
          </label>
          <Controller
            control={control}
            name='preland_id'
            render={({ ref, field }) => (
              <Select
                className='create__geos'
                inputRef={ref}
                options={prelandsOptions}
                value={prelandsOptions.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
              />
            )}
          />
          <label className='input-label' htmlFor='geos'>
            Keywords
          </label>
          <Controller
            control={control}
            name='keywords'
            render={({ ref, field }) => (
              <CreatableSelect
                inputRef={ref}
                className='create__geos'
                isMulti
                options={keys}
                value={keys?.filter((keywordItem) =>
                  Array.isArray(field.value)
                    ? field.value?.find((item) => item.label === keywordItem.label)
                    : []
                )}
                onChange={(val) => field.onChange(val.map((item) => item))}
              />
            )}
          />
          <label className='input-label' htmlFor='onelink_id'>
            Onelink_id
          </label>
          <Controller
            control={control}
            name='onelink_id'
            render={({ ref, field }) => (
              <Select
                className='create__geos'
                inputRef={ref}
                options={oneLinksOption}
                value={oneLinksOption.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
              />
            )}
          />
          <label className='input-label'>Tt Pixels</label>
          <Pixels control={control} register={register} />
          <div className='create__row' style={{ justifyContent: 'flex-start', marginTop: 20 }}>
            <Checkbox
              className='create__anti-cloak'
              {...register('anti_cloak')}
              label='Anti cloak'
            />
            <Checkbox
              {...register('start_up_ios')}
              label='Start up ios'
              style={{ marginLeft: 15 }}
            />
            <Checkbox {...register('ban_desctop')} label='Ban Desctop' style={{ marginLeft: 15 }} />
          </div>

          <div className='create__buttons'>
            {editPage.ID && (
              <>
                <Button
                  type='button'
                  onClick={() => dispatch(openModal('delete_confirm'))}
                  onKeyPress={(e) => {
                    if (e.keyCode === 13) e.preventDefault();
                  }}
                  color='dangers'
                >
                  Удалить
                </Button>
                <Button
                  type='button'
                  onClick={() => dispatch(openModal('return_url_confirm'))}
                  onKeyPress={(e) => {
                    if (e.keyCode === 13) e.preventDefault();
                  }}
                >
                  Вернуть домен
                </Button>
              </>
            )}
            <Button type='button' onClick={handleSubmit(onSubmit)}>
              {editPage.ID ? 'Сохранить' : 'Создать'}
            </Button>
            <Button onClick={handleClick} type='button'>
              Cкачать вайт
            </Button>
          </div>
        </form>
      </Modal>
      <ReturnUrlConfirm />
    </>
  );
};

export default CreateModal;
