import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { Button } from '../../baseComponents';
import { fetchOffers } from '../../newStore/actions/offer';
import { openModal } from '../../newStore/actions/modal';

import './offer.scss';
import { Form } from './Form';

export const Offer = () => {
  const dispatch = useDispatch();

  const [editedId, setEditedId] = useState(null);

  const list = useSelector((state) => state.offer.list);
  const loading = useSelector((state) => state.offer.loading);

  useEffectOnce(() => {
    dispatch(fetchOffers());
  });

  const onEdit = (id) => {
    dispatch(openModal('offer-edit'));
    setEditedId(id);
  };

  const openCreate = () => {
    dispatch(openModal('offer-edit'));
    setEditedId(null);
  };

  if (loading) return <h1>Loading</h1>;

  return (
    <>
      <div className='offer'>
        <Button onClick={openCreate}>Создать</Button>
        <div className='offer__head'>
          <div className='offer__head-item'>CreatedAt</div>
          <div className='offer__head-item'>ID</div>
          <div className='offer__head-item'>name</div>
          <div className='offer__head-item'>offer id</div>
          <div className='offer__head-item offer__head-item--hidden' />
        </div>
        <div className='offer__table'>
          {list.map((item, idx) => (
            <div className='offer__table-item' key={idx}>
              <div className='offer__table-cell'>{item.CreatedAt}</div>
              <div className='offer__table-cell'>{item.ID}</div>
              <div className='offer__table-cell'>{item.name}</div>
              <div className='offer__table-cell'>{item.offer_id}</div>
              <div className='offer__table-cell offer__table-cell--button'>
                <Button onClick={() => onEdit(item.ID)}>Редактировать</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Form id={editedId} />
    </>
  );
};
