/* eslint-disable no-console */

import { onelinkTypes, onelinkGeosTypes } from '../types/onelink';
import { api } from '../../api';
import { openModal } from './modal';

export const onelinksFetch = () => async (dispatch) => {
  try {
    dispatch({ type: onelinkTypes.loading });
    const { data } = await api.get('/get_onelink_apps');
    dispatch({ type: onelinkTypes.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: onelinkTypes.error });
  }
};

export const createOnelink = (data) => async (dispatch) => {
  try {
    await api.post('/create_onelink_app', data);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const deleteOnelink = (id) => async (dispatch) => {
  try {
    await api.delete(`/delete_onelink_app?id=${id}`);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const editOnelink = (data) => async (dispatch) => {
  try {
    await api.post('/update_onelink_app', data);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const oneLinkGeosFetch = () => async (dispatch) => {
  try {
    const { data } = await api.get('/get_geos_api');

    dispatch({ type: onelinkGeosTypes.success, payload: data });
  } catch (e) {
    console.log(e);
  }
};
