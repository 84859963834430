import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { Pagination } from '../../components/Pagination';
import { fetchPing } from '../../newStore/actions/ping';

import './ping.scss';

const excludedFields = ['ID', 'DeletedAt', 'UpdatedAt', 'link'];

const prepareList = (list) => {
  list = list.map((item) =>
    Object.keys(item).reduce(
      (acc, key) => (excludedFields.includes(key) ? acc : { ...acc, [key]: item[key] }),
      {}
    )
  );

  return list.map((item) => ({
    ...item,
    CreatedAt: `${format(parseISO(item.CreatedAt), 'yyyy-MM-dd HH:mm:ss')}`,
  }));
};

export const Ping = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  const { list, loading } = useSelector((state) => ({
    list: state.ping.list,
    loading: state.ping.loading,
  }));

  const l = prepareList(list);

  useEffect(() => {
    dispatch(
      fetchPing({
        page,
        limit,
      })
    );
  }, [page, limit]);

  return !loading ? (
    <div className='ping'>
      <Pagination
        setPage={setPage}
        setLimit={setLimit}
        limitValue={limit}
        currentPage={page}
        count={250 / limit}
        withInfo
        itemsCount={250}
      />
      <div className='ping__list'>
        <div className='ping__list-head'>
          {Object.values(Object.keys(l[0] || {})).map((item) => (
            <div>{item}</div>
          ))}
        </div>
        <div className='ping__list-body'>
          {l.map((a) => (
            <div>
              {Object.values(a).map((item) => (
                <div>{item}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Pagination
        setPage={setPage}
        setLimit={setLimit}
        limitValue={limit}
        currentPage={page}
        count={250 / limit}
        withInfo
        itemsCount={250}
      />
    </div>
  ) : (
    <h2>Loading</h2>
  );
};
