/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { useState } from 'react';

import { api } from '../../api';

import './checkDomain.scss';

export const CheckDomain = ({ domain }) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(null);

  const onCheck = async () => {
    try {
      setLoading(true);
      const { status } = await api.get(`start_checker?domain=${domain}`);
      if (status === 200) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setChecked(false);
      setLoading(false);
    }
  };

  return (
    <div className='check-domain'>
      <button onClick={onCheck}>{loading ? <div className='button-loading' /> : 'ReCheck'}</button>
      <div className={classNames('status', { 'status--success': !!checked })}>
        {checked === null
          ? ''
          : checked
          ? 'Задача отправлена, подождите 5 минут'
          : 'Произошло что-то страшное...'}
      </div>
    </div>
  );
};
