import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DataFilter } from '../../components/DataFilter';
import { CollapsedCell } from '../../baseComponents';
import { useSavedValue } from '../../hooks/useSavedValue';
import { fetchProfit } from '../../newStore/actions/profit';
import { dataFilterOptions } from '../../utils/dataFilter';
import { Pagination } from '../../components/Pagination';

import './profit.scss';

export const Profit = () => {
  const dispatch = useDispatch();

  const { value: date, onChange: setDate } = useSavedValue({
    key: 'users_page_date',
    initialValue: dataFilterOptions[0],
  });
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(25);

  const list = useSelector((state) => state.profit.list);
  const count = useSelector((state) => state.profit.count);
  const loading = useSelector((state) => state.profit.loading);
  const summ = useSelector((state) => state.profit.summ);

  useEffect(() => {
    dispatch(fetchProfit({ start: date?.value[0], end: date?.value[1], page, limit: pageLimit }));
  }, [date, page, pageLimit]);

  return (
    <div className='profit'>
      <DataFilter setDate={setDate} initialValue={date} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Pagination
          setPage={setPage}
          setLimit={setPageLimit}
          limitValue={pageLimit}
          currentPage={page}
          count={count / pageLimit}
          withInfo
          itemsCount={count}
        />
        <span style={{ margin: '20px 0 0 20px' }}>Summ: {summ}</span>
      </div>
      {!loading ? (
        <>
          <div className='profit__head'>
            <div className='profit__head-item'>CreatedAt</div>
            <div className='profit__head-item'>ID</div>
            <div className='profit__head-item'>buy_price</div>
            <div className='profit__head-item'>description</div>
            <div className='profit__head-item'>event_user</div>
            <div className='profit__head-item'>name</div>
            <div className='profit__head-item'>profit</div>
            <div className='profit__head-item'>sale_price</div>
          </div>
          <div className='profit__table'>
            {list.map((item, idx) => (
              <div className='profit__table-item' key={idx}>
                <div className='profit__table-cell'>{item.CreatedAt}</div>
                <div className='profit__table-cell'>{item.ID}</div>
                <div className='profit__table-cell'>{item.buy_price}</div>
                <div className='profit__table-cell'>
                  <CollapsedCell value={item.description} />
                </div>
                <div className='profit__table-cell'>{item.event_user}</div>
                <div className='profit__table-cell'>{item.name}</div>
                <div className='profit__table-cell'>{item.profit}</div>
                <div className='profit__table-cell'>{item.sale_price}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <h1>Loading</h1>
      )}
    </div>
  );
};
