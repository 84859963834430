import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../baseComponents';

import { Modal } from '../../components/Modal/Modal';
import { closeModal } from '../../newStore/actions/modal';
import { deletePwag } from '../../newStore/actions/pwag';

export const DeleteConfirmModal = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <Modal className='delete-confirm' id='delete_confirm--pwag'>
      <h2>Подтверждение действия</h2>
      <div className='delete-confirm__buttons'>
        <Button
          color='dangers'
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onClick={() => dispatch(deletePwag(id))}
        >
          Да
        </Button>
        <Button onClick={() => dispatch(closeModal('delete_confirm--pwa'))}>Нет</Button>
      </div>
    </Modal>
  );
};
