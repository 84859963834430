import React from 'react';
import { Modal } from '../../Modal/Modal';

const SuccessModal = () => (
  <Modal id='success'>
    <h1 style={{ textAlign: 'center' }}>Операция выполнена успешно</h1>
  </Modal>
);

export default SuccessModal;
