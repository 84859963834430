/* eslint-disable no-console */
import { streamsTypes } from '../types/streams';
import { api } from '../../api';
import { openModal } from './modal';

export const streamsFetch = () => async (dispatch) => {
  try {
    dispatch({ type: streamsTypes.loading });
    const { data } = await api.get('/get_streams');
    dispatch({ type: streamsTypes.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: streamsTypes.error });
  }
};

export const createStream = (data) => async (dispatch) => {
  try {
    await api.post('/create_stream', data);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const deleteStream = (id) => async (dispatch) => {
  try {
    await api.delete(`/delete_stream?id=${id}`);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const editStream = (data) => async (dispatch) => {
  try {
    await api.post('/update_stream', data);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};
