import { offerTypes } from '../types/offer';

const initialState = {
  list: [],
  loading: false,
};

export const offer = (state = initialState, { type, payload }) => {
  switch (type) {
    case offerTypes.loading:
      return { ...state, loading: true };
    case offerTypes.reject:
      return { ...state, loading: false };
    case offerTypes.success:
      return { ...state, loading: false, list: payload };
    case offerTypes.save:
      return {
        ...state,
        list: state.list.find((item) => item.ID === payload.ID)
          ? state.list.map((item) => (item.ID === payload.ID ? payload : item))
          : [payload, ...state.list],
      };

    default:
      return state;
  }
};
