import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Button } from '../../baseComponents';
import { Modal } from '../../components';
import { updatePrice } from '../../newStore/actions/price';

export const Form = ({ id }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();

  const list = useSelector((state) => state.price.list);

  const editedItem = useMemo(() => list.find((item) => item.ID === id), [id, list]);

  useEffect(() => {
    if (editedItem) {
      setValue('name', editedItem.name);
      setValue('value', editedItem.value);
      setValue('currency', editedItem.currency);
    }
  }, [editedItem]);

  const onSubmit = (data) => dispatch(updatePrice({ ...editedItem, ...data }));

  return (
    <Modal id='price-edit'>
      <form className='price-edit' onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder='Name' {...register('name')} />
        <Input placeholder='Value' {...register('value')} />
        <Input placeholder='Currency' {...register('currency')} />
        <Button type='submit'>Сохранить</Button>
      </form>
    </Modal>
  );
};
