import React, { useState } from 'react';
import Select from 'react-select';
import { RangeDatePicker } from '@y0c/react-datepicker';
import DateTimePicker from '@wojtekmaj/react-datetimerange-picker';
import unix from 'date-fns/getUnixTime';
import { fromUnixTime, setHours, setMinutes, setSeconds } from 'date-fns';

import { dataFilterOptions } from '../../utils/dataFilter';

import './dateFilter.scss';

export const DataFilter = ({ setDate, initialValue }) => {
  const [dateTimeValue, setDateTimeValue] = useState(null);
  const [activePicker, setActivePicker] = useState(initialValue?.activePicker || null);

  const handleChange = (val) => {
    if (val.value === 'date') {
      setActivePicker('date');

      return;
    }
    if (val.value === 'time') {
      setActivePicker('time');

      return;
    }

    setActivePicker(null);

    if (initialValue) {
      setDate(val);

      return;
    }

    setDate(val.value);
  };

  const onDateChange = (start, end) => {
    const value = [
      start ? unix(setSeconds(setMinutes(setHours(start, 0), 0), 0)) : null,
      end ? unix(setSeconds(setMinutes(setHours(end, 0), 0), 0)) : null,
    ];

    setDate({ label: 'Интервал дат', value, activePicker: 'date' });
  };

  const onDateTimeChange = (date) => {
    setDateTimeValue(date);
    const value = [date[0] ? unix(date[0]) : null, date[1] ? unix(date[1]) : null];

    setDate({ label: 'Интервал времени', value, activePicker: 'time' });
  };

  return (
    <div className='date-filter'>
      {activePicker === 'date' && (
        <RangeDatePicker
          onChange={onDateChange}
          initialStartDate={
            initialValue?.activePicker === 'date'
              ? fromUnixTime(initialValue.value[0])
              : fromUnixTime(dataFilterOptions[0].value[0])
          }
          initialEndDate={
            initialValue?.activePicker === 'date'
              ? fromUnixTime(initialValue.value[1])
              : fromUnixTime(dataFilterOptions[0].value[0])
          }
        />
      )}
      {activePicker === 'time' && (
        <DateTimePicker onChange={onDateTimeChange} value={dateTimeValue} disableClock />
      )}
      <Select
        defaultValue={
          initialValue
            ? dataFilterOptions.find((item) => item.label === initialValue.label)
            : dataFilterOptions[0]
        }
        options={dataFilterOptions}
        onChange={handleChange}
        className='date-filter__select'
      />
    </div>
  );
};
