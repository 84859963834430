import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, Input } from '../../baseComponents';
import { Modal } from '../../components';
import { createIosApps, updateIosApps } from '../../newStore/actions/iosApp';

export const Form = ({ edit, onHide }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (!isEmpty(edit)) {
      setValue('app_name', edit.app_name);
      setValue('one_link', edit.one_link);
    }
  }, [edit]);

  const onSubmit = (data) => {
    if (isEmpty(edit)) {
      dispatch(createIosApps(data));
    } else {
      dispatch(
        updateIosApps({
          ...edit,
          ...data,
        })
      );
    }
  };

  const onClose = () => {
    if (onHide) onHide();
    reset();
  };

  return (
    <Modal id='ios-app-edit' onHide={onClose}>
      <form className='ios-edit' style={{ padding: 20 }} onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder='app_name'
          error={get(errors, 'app_name.message')}
          {...register('app_name', { required: true })}
        />
        <Input
          placeholder='one_link'
          error={get(errors, 'one_link.message')}
          {...register('one_link', { required: true })}
        />
        <Button className='ios-edit__submit' type='submit'>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
