import React from 'react';
import reactDom from 'react-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../newStore/actions/modal';

import './modal.scss';

export const Modal = ({ children, id, onHide, className }) => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal.show);

  const isOpen = () => !!modal.includes(id);

  const handleClose = () => {
    dispatch(closeModal(id));

    if (onHide) {
      onHide();
    }
  };

  return (
    isOpen() &&
    reactDom.createPortal(
      <div className={classNames('modal', { 'modal--open': isOpen() })} id={id}>
        <div className='modal__overlay' onClick={handleClose} />
        <div className={classNames('modal__body', className)}>{children}</div>
      </div>,
      document.body
    )
  );
};
