export const affilatesTypes = {
  list: {
    loading: 'affilates/list/loading',
    success: 'affilates/list/success',
    error: 'affilates/list/error',
  },
  current: {
    loading: 'affilates/current/loading',
    success: 'affilates/current/success',
    error: 'affilates/current/error',
  },
  clients: {
    loading: 'affilates/clients/loading',
    success: 'affilates/clients/success',
    error: 'affilates/clients/error',
  },
};
