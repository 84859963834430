import { updateTypes } from '../types/update';

const initialState = {
  page: {},
  geos: [],
};

export const update = (state = initialState, action) => {
  switch (action.type) {
    case updateTypes.set:
      return {
        ...state,
        page: action.payload,
      };
    case updateTypes.setGeos:
      return {
        ...state,
        geos: action.payload,
      };
    case updateTypes.reset:
      return {
        ...state,
        page: {},
      };
    default:
      return state;
  }
};
