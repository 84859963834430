import { trafficMethodsTypes } from '../types/trafficMethods';

const initialState = {
  loading: false,
  list: [],
};

export const trafficMethods = (state = initialState, action) => {
  switch (action.type) {
    case trafficMethodsTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case trafficMethodsTypes.error:
      return {
        ...state,
        loading: false,
      };
    case trafficMethodsTypes.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
