/* eslint-disable no-console */
import { api } from '../../api';
import { closeModal, openModal } from './modal';
import { pwagsTypes } from '../types/pwags';

export const pwagsFetch = () => async (dispatch) => {
  try {
    dispatch({ type: pwagsTypes.loading });
    const { data } = await api.get('/get_pwags');
    dispatch({ type: pwagsTypes.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: pwagsTypes.error });
  }
};

export const currentPwagFetch = (id) => async (dispatch) => {
  try {
    dispatch({ type: pwagsTypes.current.loading });
    const { data } = await api.get('/get_pwag_by_id', { params: { id } });
    dispatch({ type: pwagsTypes.current.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: pwagsTypes.current.error });
  }
};

export const createPwag = (data) => async (dispatch) => {
  try {
    await api.post('/create_pwag', data);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const deletePwag = (id) => async (dispatch) => {
  try {
    await api.delete(`/delete_pwag?id=${id}`);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const editPwag = (data) => async (dispatch) => {
  try {
    const { data: newPwag } = await api.post('/update_pwag', data);
    dispatch(openModal('success'));

    setTimeout(() => dispatch(closeModal('success')), 1500);

    dispatch({ type: pwagsTypes.current.success, payload: newPwag });

    // window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const pwagsImagesFetch = () => async (dispatch) => {
  try {
    const { data } = await api.get('/get_images');

    dispatch({ type: pwagsTypes.images.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: pwagsTypes.error });
  }
};

export const fetchLanguages = () => async (dispatch) => {
  try {
    const { data } = await api.get('/get_languages');

    dispatch({ type: pwagsTypes.languages.success, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const pwagsTranslationsFetch = () => async (dispatch) => {
  try {
    dispatch({ type: pwagsTypes.translates.loading });
    const { data } = await api.get('/get_pwag_translates');

    dispatch({ type: pwagsTypes.translates.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: pwagsTypes.translates.error });
  }
};

export const savePwagsTranslations = (values) => async (dispatch) => {
  try {
    dispatch({ type: pwagsTypes.translates.loading });
    const { data } = await api.post('/save_pwag_translate', values);

    dispatch({ type: pwagsTypes.translates.save, payload: data });

    dispatch(closeModal('add-pwag-translate'));
    dispatch(openModal('success'));
    setTimeout(() => dispatch(closeModal('success')), 1500);
  } catch (e) {
    console.log(e);
  }
};
