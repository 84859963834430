import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Input } from '../../baseComponents';
import { createDomainZone } from '../../newStore/actions/domains';

export const AddZone = () => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(createDomainZone(data.zone));
    reset();
  };

  return (
    <div className='add-domain'>
      <Button onClick={() => setIsActive((prev) => !prev)}>Создать доменную зону</Button>
      <div
        className={classNames('add-domain__dropdown', {
          'add-domain__dropdown--active': isActive,
        })}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type='text'
            placeholder='Zone'
            {...register('zone', { required: true })}
            error={!!errors.domain}
          />
          <Button type='submit'>Создать</Button>
        </form>
      </div>
    </div>
  );
};
