/* eslint-disable no-console */
import { api } from '../../api';
import { updateTypes } from '../types/update';
import { closeModal, openModal } from './modal';

export const setEditPage = (page) => ({
  type: updateTypes.set,
  payload: page,
});

export const resetPage = () => ({
  type: updateTypes.reset,
});

export const geosFetch = () => async (dispatch) => {
  try {
    const { data } = await api.get('/get_geos');

    dispatch({ type: updateTypes.setGeos, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const deletePage = async (id) => {
  try {
    await api.delete(`/delete_page?id=${id}`);
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const createFetch = (data) => async (dispatch) => {
  try {
    await api.post('/create_page', data);
    // window.location.reload();

    dispatch(closeModal('create'));
    dispatch(openModal('success'));
  } catch (e) {
    console.log(e);
    window.location.reload();
    dispatch(openModal('error'));
  }
};

export const editFetch = (data) => async (dispatch) => {
  try {
    await api.post('/update_page', data);
    console.log(data);
    window.location.reload();

    dispatch(closeModal('create'));
    dispatch(openModal('success'));
  } catch (e) {
    console.log(e);
    window.location.reload();
    dispatch(openModal('error'));
  }
};
