import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Select from 'react-select';

import {
  cloakNumOptions,
  getCloakGeo,
  getCloakNum,
  setCloakGeo,
  setCloakNum,
} from '../../utils/cloakNum';
import { fetchInstances } from '../../newStore/actions/instances';
import { Button } from '../../baseComponents';

import './header.scss';

export const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.instances);
  const logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  const handleChangeCloakNum = (val) => {
    setCloakNum(val);
    history.push('/');
    window.location.reload();
  };

  const handleChangeCloakGeo = (val) => {
    setCloakGeo(val);
    history.push('/');
    window.location.reload();
  };

  const geoList = list.map((item) => {
    return {
      value: item.cloak_number,
      label: item.name,
    };
  });

  useEffect(() => {
    dispatch(fetchInstances());
  }, []);

  return (
    <div className='header'>
      <div className='header__inner'>
        <div className='header__cloak-num'>
          <span className='header__cloak-num-title'>Cloak num:</span>
          <Select
            className='header__cloak-num-select'
            defaultValue={cloakNumOptions.find((item) => item.value === getCloakNum())}
            options={cloakNumOptions}
            onChange={(v) => handleChangeCloakNum(v.value)}
          />
        </div>
        <div className='header__cloak-geo'>
          <span className='header__cloak-geo-title'>Cloak geo:</span>
          {!loading && (
            <Select
              className='header__cloak-geo-select'
              defaultValue={geoList.find((item) => item.value === +getCloakGeo())}
              options={geoList}
              onChange={(v) => handleChangeCloakGeo(v.value)}
            />
          )}
        </div>
        <nav className='header__nav'>
          <NavLink exact className='header__nav-link' to='/'>
            Main
          </NavLink>
          <NavLink className='header__nav-link' to='/managers_info'>
            Lost_domains
          </NavLink>
          <NavLink className='header__nav-link' to='/errors'>
            Errors
          </NavLink>
          <NavLink className='header__nav-link' to='/domains'>
            Domains
          </NavLink>
          <NavLink className='header__nav-link' to='/help'>
            Helps
          </NavLink>
          <NavLink className='header__nav-link' to='/streams'>
            Streams
          </NavLink>
          <NavLink className='header__nav-link' to='/bot_text'>
            Bot text
          </NavLink>
          <NavLink className='header__nav-link' to='/users'>
            Users
          </NavLink>
          <NavLink className='header__nav-link' to='/affilates'>
            Affilates
          </NavLink>
          <NavLink className='header__nav-link' to='/pwags'>
            Pwags
          </NavLink>
          <NavLink className='header__nav-link' to='/images'>
            Images
          </NavLink>
          <NavLink className='header__nav-link' to='/pwag_translates'>
            Pwag translates
          </NavLink>
          <NavLink className='header__nav-link' to='/locations'>
            Locations
          </NavLink>
          <NavLink className='header__nav-link' to='/ping'>
            Ping
          </NavLink>
          <NavLink className='header__nav-link' to='/Managers'>
            Managers
          </NavLink>
          <NavLink className='header__nav-link' to='/iosApp'>
            Ios Apps
          </NavLink>
          <NavLink className='header__nav-link' to='/profit'>
            Profit
          </NavLink>
          <NavLink className='header__nav-link' to='/price'>
            Price
          </NavLink>
          <NavLink className='header__nav-link' to='/offers'>
            Offers
          </NavLink>
          <NavLink className='header__nav-link' to='/clo_block'>
            CloBlock
          </NavLink>
          <NavLink className='header__nav-link' to='/onelink'>
            Onelink apps
          </NavLink>
          <NavLink className='header__nav-link' to='/special_values'>
            SpecialValues
          </NavLink>
        </nav>
        <div className='header__account'>
          <Button onClick={logout} color='dangers'>
            Выйти
          </Button>
        </div>
      </div>
    </div>
  );
};
