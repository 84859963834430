import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { Input, DeleteButton, Button } from '../../../baseComponents';

export const Pixels = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tt_pixels',
  });

  return (
    <div className='pixels'>
      {fields.map((item, idx) => (
        <div className='pixels__item' key={item.id}>
          <Controller
            control={control}
            name={`tt_pixels.${idx}.value`}
            render={({ field }) => <Input {...field} />}
          />
          <DeleteButton onClick={() => remove(idx)} />
        </div>
      ))}
      <Button onClick={() => append({ value: '' })}>Добавить</Button>
    </div>
  );
};
