import { createEvent, createStore } from 'effector';

export const $modal = createStore([]);

export const openModal = createEvent();
export const closeModal = createEvent();

const open = (state, data) => [...state.filter((item) => item !== data), data];
const close = (state, data) => state.filter((item) => item !== data);

$modal.on(openModal, open).on(closeModal, close);
