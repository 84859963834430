import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../store/modal';

import { Modal } from '../../Modal/Modal';

import { deleteFtp } from '../../../newStore/actions/ftpList';
import { Button } from '../../../baseComponents';

export const DeleteConfirmModal = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <Modal className='delete-confirm' id='delete_confirm--ftp'>
      <h2>Подтверждение действия</h2>
      <div className='delete-confirm__buttons'>
        <Button
          color='dangers'
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onClick={() => dispatch(deleteFtp(id))}
        >
          Да
        </Button>
        <Button onClick={() => closeModal('delete_confirm')}>Нет</Button>
      </div>
    </Modal>
  );
};
