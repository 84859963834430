import { iosAppTypes } from '../types/iosApp';

const initialState = {
  list: [],
};

export const iosApp = (state = initialState, action) => {
  switch (action.type) {
    case iosAppTypes.set:
      return {
        ...state,
        list: action.payload,
      };
    case iosAppTypes.edit:
      return {
        ...state,
        list: state.list.map((item) => (item.ID === action.payload.ID ? action.payload : item)),
      };
    case iosAppTypes.add:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    default:
      return state;
  }
};
