import { cloBlockTypes } from '../types/cloBlock';

import { api } from '../../api';

export const fetchCloBlocks = () => async (dispatch) => {
  dispatch({ type: cloBlockTypes.loading });

  try {
    const { data } = await api.get('get_blocked_info');

    dispatch({ type: cloBlockTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: cloBlockTypes.reject });
  }
};

export const toggleCloBlocks =
  ({ flag, cloak_geo_id }) =>
  async (dispatch) => {
    dispatch({ type: cloBlockTypes.loading });

    try {
      const { data } = await api.get('change_blocked_status', { params: { flag, cloak_geo_id } });

      dispatch({ type: cloBlockTypes.save, payload: data });
    } catch (e) {
      dispatch({ type: cloBlockTypes.reject });
    }
  };
