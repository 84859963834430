export const prepareGeosOptions = (geos) => {
  if (!geos) return [];
  if (!Array.isArray(geos)) return [];

  return geos?.map((item) => ({
    label: item.name,
    value: item,
  }));
};

export const prepareCreateData = (data) => ({
  ...data,
  mass_ban: false,
  type: +data.type,
  type_black: +data.type_black,
  bad_count: +data.bad_count,
});

export const typesOptions = [
  { value: 0, label: 'Html' },
  { value: 1, label: 'Proxy' },
  { value: 2, label: 'FTP' },
];

export const blackTypesOptions = [
  { value: 0, label: 'Html' },
  { value: 1, label: 'Proxy' },
  { value: 2, label: 'FTP' },
  { value: 3, label: 'Redirect(keitaro)' },
  { value: 4, label: 'Redirect with params' },
  { value: 5, label: 'NoKeitaro redirect' },
  { value: 6, label: 'Meta/js' },
  { value: 7, label: 'Руйтаро' },
  { value: 8, label: 'BlackPageTaro' },
  { value: 9, label: 'ПрямойГп' },
  { value: 10, label: 'FilterMethod' },
  { value: 11, label: 'ФродоМетод' },
  { value: 12, label: 'ReturnMyPwa' },
  { value: 13, label: 'Анозерфильтр' },
];

export const typesLabels = ['Html', 'Proxy', 'FTP', 'Redirect(keitaro)'];

export const prepareKeywordsOptions = (list) => {
  if (list) return list?.map((item) => ({ label: item, value: item }));

  return [];
};

export const keyIdOptions = [
  { label: 'sub_id_1', value: 1 },
  { label: 'sub_id_2', value: 2 },
  { label: 'sub_id_3', value: 3 },
  { label: 'sub_id_4', value: 4 },
  { label: 'sub_id_5', value: 5 },
  { label: 'sub_id_6', value: 6 },
];

export const getPwagOptions = (list) =>
  list?.map((item) => ({ label: item.app_name, value: item.ID })) || [];
