/* eslint-disable no-console */
import { domainsTypes, domainZonesTypes } from '../types/domains';
import { api } from '../../api';

import { openModal } from './modal';

export const domainsFetch = () => async (dispatch) => {
  try {
    dispatch({ type: domainsTypes.loading });

    const { data } = await api.get('/get_zones');
    dispatch({
      type: domainsTypes.success,
      payload: {
        list: data.reduce(
          (acc, item) => [...acc, { name: item.zone, domains: item.domains, id: item.ID }],
          []
        ),
        domainZonesList: data.map((item) => ({
          zone: item.zone,
          id: item.ID,
        })),
      },
    });
  } catch (e) {
    dispatch({ type: domainsTypes.error });
  }
};

export const createDomain = (domain) => async (dispatch) => {
  try {
    await api.post(
      '/create_domain',
      { domain: domain.domain, activated: domain.activated },
      {
        params: { zone_id: domain.zone },
      }
    );
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const deleteDomain = (id) => async (dispatch) => {
  try {
    await api.delete(`/delete_domain?id=${id}`);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const changeStatusDomain = (id) => async (dispatch) => {
  try {
    await api.get(`/change_status_domain?id=${id}`);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const domainZonesFetch = () => async (dispatch) => {
  try {
    dispatch({ type: domainsTypes.loading });

    const { data } = await api.get('/get_domain_zones');
    await api.get('/get_zones');
    dispatch({ type: domainZonesTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: domainsTypes.error });
  }
};

export const activateDomainZone = (zone) => async (dispatch) => {
  try {
    await api.get('/activate_zone', { params: { zone } });
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const deactivateDomainZone = (zone) => async (dispatch) => {
  try {
    await api.get('/deactivate_zone', { params: { zone } });
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const createDomainZone = (zone) => async (dispatch) => {
  try {
    await api.post('/create_zone', { zone });
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const changeDomainPrice = (domain_id, price) => async (dispatch) => {
  try {
    await api.get('/change_price_domain', { params: { domain_id, price } });
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const changeZonePrice = (zone_id, price) => async (dispatch) => {
  try {
    await api.get('/change_price_zone', { params: { zone_id, price } });
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const changeDefaultZonePrice = (zone) => async (dispatch) => {
  try {
    await api.post('/update_default_price', { ...zone });
    window.location.reload();
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const createDomainZoneBot = (zone) => async (dispatch) => {
  try {
    dispatch({ type: domainZonesTypes.create });

    await api.post('/create_regru_task', { ...zone });

    dispatch(openModal('success'));

    dispatch({ type: domainZonesTypes.createSuccess });
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const changeDomainGeo = (domain, geo, zoneId) => async (dispatch) => {
  try {
    const { data } = await api.post(`/move_domain?geo_target=${geo}`, domain);

    dispatch({ type: domainsTypes.update, payload: { domain: data, zoneId } });
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};
