import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { Button } from '../../baseComponents';
import { fetchPrice } from '../../newStore/actions/price';
import { openModal } from '../../newStore/actions/modal';

import './price.scss';
import { Form } from './Form';

export const Price = () => {
  const dispatch = useDispatch();

  const [editedId, setEditedId] = useState(null);

  const list = useSelector((state) => state.price.list);
  const loading = useSelector((state) => state.price.loading);

  useEffectOnce(() => {
    dispatch(fetchPrice());
  });

  const onEdit = (id) => {
    dispatch(openModal('price-edit'));
    setEditedId(id);
  };

  if (loading) return <h1>Loading</h1>;

  return (
    <>
      <div className='price'>
        <div className='price__head'>
          <div className='price__head-item'>CreatedAt</div>
          <div className='price__head-item'>ID</div>
          <div className='price__head-item'>name</div>
          <div className='price__head-item'>currency</div>
          <div className='price__head-item'>value</div>
          <div className='price__head-item price__head-item--hidden' />
        </div>
        <div className='price__table'>
          {list.map((item, idx) => (
            <div className='price__table-item' key={idx}>
              <div className='price__table-cell'>{item.CreatedAt}</div>
              <div className='price__table-cell'>{item.ID}</div>
              <div className='price__table-cell'>{item.name}</div>
              <div className='price__table-cell'>{item.currency}</div>
              <div className='price__table-cell'>{item.value}</div>
              <div className='price__table-cell price__table-cell--button'>
                <Button onClick={() => onEdit(item.ID)}>Редактировать</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Form id={editedId} />
    </>
  );
};
