/* eslint-disable no-console */
import { helpTypes } from '../types/help';
import { api } from '../../api';

export const helpFetch = () => async (dispatch) => {
  try {
    dispatch({ type: helpTypes.loading });
    const { data } = await api.get('/get_helps');

    dispatch({ type: helpTypes.success, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: helpTypes.error });
  }
};
