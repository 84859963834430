import { api } from '../../api';

import { instancesTypes } from '../types/instances';

export const fetchInstances = () => async (dispatch) => {
  dispatch({ type: instancesTypes.loading });
  try {
    const { data } = await api.get('/get_instances');

    dispatch({ type: instancesTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: instancesTypes.reject });
  }
};
