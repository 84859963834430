import { modalTypes } from '../types/modal';

export const openModal = (id) => ({
  type: modalTypes.open,
  payload: id,
});

export const closeModal = (id) => ({
  type: modalTypes.close,
  payload: id,
});
