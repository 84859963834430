import { unset, isBoolean } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BooleanMarker, Button } from '../../baseComponents';

import { fetchManagers } from '../../newStore/actions/managers';
import { openModal } from '../../newStore/actions/modal';
import { DeleteManagerModal } from './Confirm';
import { Form } from './Form';

import './managers.scss';

const excludedKeys = ['CreatedAt', 'UpdatedAt', 'DeletedAt', 'password', 'token', ''];

export const Managers = () => {
  const dispatch = useDispatch();
  const [editedObj, setEdited] = useState(null);
  const [id, setId] = useState(null);
  const { loading, list: baseList } = useSelector((state) => state.managers);

  useEffect(() => {
    dispatch(fetchManagers());
  }, []);

  const list = useMemo(
    () =>
      baseList.map((item) => {
        excludedKeys.forEach((e) => unset(item, e));

        return item;
      }),
    [baseList]
  );

  const openForm = () => dispatch(openModal('managers-form'));

  const openEdit = (edited) => {
    setEdited(edited);
    openForm();
  };

  const deleteManager = (deletedId) => {
    setId(deletedId);
    dispatch(openModal('delete_confirm--manager'));
  };

  return !loading ? (
    <div className='managers'>
      <Button onClick={openForm}>Создать</Button>
      <div className='managers__list'>
        <div className='managers__list-head'>
          <div />
          {Object.values(Object.keys(list[0] || {})).map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
        </div>
        <div className='managers__list-body'>
          {list.map((a, key) => (
            <div key={key}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button style={{ marginBottom: 10 }} onClick={() => openEdit(a)}>
                  Редактировать
                </Button>
                {!a.admin && (
                  <Button color='dangers' onClick={() => deleteManager(a.ID)}>
                    Удалить
                  </Button>
                )}
              </div>
              {Object.values(a).map((item, idx) => {
                if (isBoolean(item)) {
                  return (
                    <div key={idx}>
                      <BooleanMarker value={item} />
                    </div>
                  );
                }

                return <div key={idx}>{item}</div>;
              })}
            </div>
          ))}
        </div>
      </div>
      <Form edited={editedObj} />
      <DeleteManagerModal id={id} />
    </div>
  ) : (
    <h2>Loading</h2>
  );
};
