import React from 'react';
import { useDispatch } from 'react-redux';
import { upload } from '../../newStore/actions/mainPage';

import './addFile.scss';

export const AddFile = ({ text, uploadUrl }) => {
  const dispatch = useDispatch();

  const onChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      dispatch(upload({ file, uploadUrl }));
    }
  };

  return (
    <label className='button add-file'>
      {text}
      <input type='file' onChange={onChange} style={{ display: 'none' }} accept='application/zip' />
    </label>
  );
};
