import React from 'react';
import { Modal } from '../../Modal/Modal';

const ErrorModal = () => (
  <Modal id='error'>
    <h1 style={{ textAlign: 'center' }}>Произошла ошибка</h1>
  </Modal>
);

export default ErrorModal;
