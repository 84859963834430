import { botTextTypes } from '../types/botText';

import { api } from '../../api';
import { openModal } from './modal';

export const botTextFetch = () => async (dispatch) => {
  dispatch({ type: botTextTypes.loading });
  try {
    const { data } = await api.get('get_dynamic_texts');

    dispatch({ type: botTextTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: botTextTypes.reject });
  }
};

export const updateBotTextFetch = (value) => async (dispatch) => {
  dispatch({ type: botTextTypes.loading });
  try {
    await api.post('update_dynamic_text', value);

    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    dispatch(openModal('error'));
  }
};
