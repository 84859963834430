import { priceTypes } from '../types/price';

const initialState = {
  loading: false,
  list: [],
};

export const price = (state = initialState, action) => {
  switch (action.type) {
    case priceTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case priceTypes.reject:
      return {
        ...state,
        loading: false,
      };
    case priceTypes.success:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case priceTypes.update:
      return {
        ...state,
        list: state.list.map((item) => (item.ID === action.payload.ID ? action.payload : item)),
      };
    default:
      return state;
  }
};
