import { lostDomainsTypes } from '../types/lostDomains';

import { api } from '../../api';

export const lostDomainsFetch = () => async (dispatch) => {
  dispatch({ type: lostDomainsTypes.loading });

  try {
    const { data } = await api.get('get_lost_domains');

    dispatch({ type: lostDomainsTypes.success, payload: data });
  } catch (e) {
    dispatch({ type: lostDomainsTypes.reject });
  }
};
