/* eslint-disable no-console */

import { api } from '../../api';
import { specialValuesTypes } from '../types/specialValues';
import { openModal } from './modal';

export const fetchSpecialValues = () => async (dispatch) => {
  try {
    dispatch({ type: specialValuesTypes.loading });
    const { data } = await api.get('/get_special_values');
    dispatch({ type: specialValuesTypes.success, paylaod: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: specialValuesTypes.error });
  }
};

export const updateSpecialValues = (data) => async (dispatch) => {
  try {
    await api.post('/update_special_value', data);
    dispatch(openModal('success'));
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};
