import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { userApi } from '../../apiModule';

import { Button } from '../../baseComponents/Button/Button';
import { Input } from '../../baseComponents/Input/Input';

import './login.scss';

export const Login = () => {
  const { isLoading, mutate } = useMutation(userApi.login, {
    onSuccess: (response) => {
      localStorage.setItem('token', response.token);
      window.location.reload();
    },
  });
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <div className='login'>
      <form className='login__form' onSubmit={handleSubmit(onSubmit)}>
        <Input type='text' {...register('username')} placeholder='Username' loading={isLoading} />
        <Input
          type='password'
          {...register('password')}
          placeholder='Password'
          loading={isLoading}
        />
        <Button type='submit' loading={isLoading} className='login__button'>
          Войти
        </Button>
      </form>
    </div>
  );
};
