import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { DeleteButton } from '../../baseComponents';
import { ReactComponent as PhotoIcon } from './assets/camera.svg';
import { uploadImage } from '../../newStore/actions/mainPage';
import { pwagsTypes } from '../../newStore/types/pwags';
import { getImagePath } from '../../utils/cloakNum';

export const Image = ({ onChange, value, images, title, className, withDelete }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const currentImage = images?.find((item) => item.ID === value) || null;

  const changeImage = async (e) => {
    const { files } = e.target;

    if (files) {
      try {
        const image = await dispatch(uploadImage(files[0]));
        dispatch({ type: pwagsTypes.images.add, payload: image });
        onChange(image?.ID);
      } catch (err) {
        setError('Во время загрузки произошла ошибка');
      }

      setMenuOpen(false);
    }
  };

  return (
    <div className={classNames('image', className)}>
      <div>
        <h2>{title}</h2>
        <span style={{ color: 'red' }}>{error}</span>
        <div
          className={classNames('image__current', {
            'image__current--active': !!currentImage,
          })}
          onClick={() => setMenuOpen((prev) => !prev)}
          aria-hidden='true'
        >
          {currentImage ? (
            <img src={getImagePath(currentImage.name)} alt={currentImage.name} />
          ) : (
            <PhotoIcon />
          )}
        </div>
      </div>

      {withDelete && <DeleteButton onClick={() => onChange(0)} />}
      {menuOpen && (
        <div className='image__menu'>
          <label className='image__menu-add'>
            <PhotoIcon />
            Загрузить
            <input type='file' style={{ display: 'none' }} onChange={changeImage} />
          </label>
          <div className='image__menu-list'>
            {images?.map((item, idx) => (
              <div
                className='image__menu-list-item'
                key={idx}
                onClick={() => {
                  onChange(item.ID);
                  setMenuOpen(false);
                }}
                aria-hidden='true'
              >
                <img src={getImagePath(item.name)} alt={item.name} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
