import axios from 'axios';
import { getCloakGeo, getCloakNum } from './utils/cloakNum';
import { getToken } from './utils/getToken';

const token = getToken();
const cloakNum = getCloakNum();
const cloakGeo = getCloakGeo();

export const api = axios.create({
  baseURL: 'https://api.tgsocialind.site',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  },
  params: {
    cloak_num: cloakNum,
    cloak_geo: cloakGeo,
  },
});

export const apiWithoutExtras = axios.create({
  baseURL: 'https://api.tgsocialind.site',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  },
});
