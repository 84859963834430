export const onelinkTypes = {
  loading: 'onelink/loading',
  success: 'onelink/success',
  error: 'onelink/error',
};

export const onelinkGeosTypes = {
  loading: 'onelinkGeos/loading',
  success: 'onelinkGeos/success',
  error: 'onelinkGeos/error',
};
