import { streamsTypes } from '../types/streams';

const initialState = {
  loading: false,
  list: [],
};

export const streams = (state = initialState, action) => {
  switch (action.type) {
    case streamsTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case streamsTypes.error:
      return {
        ...state,
        loading: false,
      };
    case streamsTypes.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
