/* eslint-disable no-console */
export const ls = {
  get(key) {
    try {
      const value = JSON.parse(localStorage.getItem(key));
      return value;
    } catch (e) {
      console.log(`ls error ${key}`, e);
      return localStorage.getItem(key);
    }
  },
  set(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.log(`ls error ${key}`, e);
    }
  },
  remove(key) {
    localStorage.removeItem(key);
  },
};
