import { useState } from 'react';
import { ls } from '../utils/ls';

const getValue = (v) => v !== 'null' && v !== 'undefined' && v;

export const useSavedValue = ({ key, initialValue }) => {
  const [value, setValue] = useState(getValue(ls.get(key) || `${ls.get(key)}`) || initialValue);

  const onChange = (newValue) => {
    setValue(newValue);
    ls.set(key, newValue);
  };

  return {
    value,
    onChange,
  };
};
