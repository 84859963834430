import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import parcsISO from 'date-fns/parseISO';

export const getOptionsFromLS = (listName = 'options') => {
  if (!Array.isArray(JSON.parse(localStorage.getItem(listName)))) {
    localStorage.setItem(listName, JSON.stringify([]));
    return [];
  }

  return JSON.parse(localStorage.getItem(listName));
};

const getClickCount = (domain, list) =>
  list.reduce((acc, item) => {
    if (item.history.host === domain) {
      return acc + 1;
    }
    return acc;
  }, 0);

export const prepareMainTableData = (users, bots) =>
  users?.reduce((acc, item) => {
    if (acc.find((value) => value.domain === item.history.host)) return acc;

    return [
      ...acc,
      {
        domain: item.history.host,
        black: getClickCount(item.history.host, users),
        white: getClickCount(item.history.host, bots),
      },
    ];
  }, []);

export const prepareCurrentTableRow = (obj) => {
  const newObj = { ...obj };
  delete newObj.dump;
  delete newObj.history;

  return { ...obj.dump, ...obj.history, ...newObj };
};

export const getColumns = (data) => {
  if (!data) return;
  delete data.geo;
  const currentData = {
    ...data,
    ...data.history,
    ...data.dump,
    ID: data.ID,
  };

  return Object.keys(currentData)
    .map((item) => {
      if (
        (currentData[item] && currentData[item] !== false) ||
        currentData[item] === '' ||
        typeof currentData[item] === 'boolean'
      ) {
        return {
          label: item,
          field: item,
        };
      }

      return undefined;
    })
    .filter(Boolean)
    .filter((item) => getOptionsFromLS().find((optionItem) => optionItem.value === item.label))
    .filter((item) => item.label !== 'history');
};

export const prepareCurrentTableColumn = (list) =>
  list.map((item) => {
    delete item?.DeletedAt;
    delete item?.UpdatedAt;
    delete item?.dump?.DeletedAt;
    delete item?.dump?.UpdatedAt;
    delete item?.history?.DeletedAt;
    delete item?.history?.UpdatedAt;
    delete item?.dump_id;
    delete item?.history?.longitude;
    delete item?.history?.latitude;
    delete item?.history?.postal;
    delete item?.history?.european_union;
    delete item?.history?.european_union;
    delete item?.HistoryID;
    item.checked = item.checked === null ? 'not checked' : `${item.checked}`;
    Object.keys(item).forEach((key) => {
      if (typeof item[key] === 'boolean') item[key] = `${item[key]}`;
    });
    item.CreatedAt = `${format(parcsISO(item.CreatedAt), 'yyyy-MM-dd HH:mm:ss')}`;

    return item;
  });

export const getCurrentTableOptions = (data) => {
  if (!data) return;

  const currentData = {
    ...data,
    ...data.history,
    ...data.dump,
    ID: data.ID,
  };

  return Object.keys(currentData)
    .map((item) => {
      if (
        (currentData[item] && currentData[item] !== false) ||
        currentData[item] === '' ||
        typeof currentData[item] === 'boolean'
      ) {
        return {
          label: item,
          value: item,
        };
      }

      return null;
    })
    .filter(Boolean)
    .filter((item) => item.label !== 'history');
};

const formatDate = (date) => format(parseISO(date.split('.')[0]), 'yyyy-MM-dd HH:mm:ss');

export const prepareUsersList = (list) =>
  list?.map((item) => {
    delete item.DeletedAt;

    const dataObj = {
      CreatedAt: item.CreatedAt ? formatDate(item.CreatedAt) : '',
      UpdatedAt: item.UpdatedAt ? formatDate(item.UpdatedAt) : '',
      updated_at: item.updated_at ? formatDate(item.updated_at) : '',
      activated_at: item.activated_at ? formatDate(item.activated_at) : '',
      subscription_at: item.subscription_at ? formatDate(item.subscription_at) : '',
    };

    Object.keys(dataObj).forEach((key) => !dataObj[key] && delete dataObj[key]);

    return {
      ...item,
      ...dataObj,
    };
  });

export const getAvailableUserListFields = (list) => {
  const availableFields = getOptionsFromLS('users_options').map((item) => item.value);

  return prepareUsersList(list).map((item) =>
    Object.keys(item).reduce(
      (acc, field) => (availableFields.includes(field) ? { ...acc, [field]: item[field] } : acc),
      {}
    )
  );
};

export const getBotEventsTypesOptions = (list) =>
  list
    ? [
        { label: 'Все', value: -1 },
        ...list.map((item) => ({ label: item.event, value: item.event_type })),
      ]
    : [];

export const prepareBotsEvents = (eventsObj = {}, events) => {
  const r = Object.keys(eventsObj || {}).map((item) => ({
    title: events.find((eventItem) => item.replace('event', '') === `${eventItem.value}`)?.label,
    value: eventsObj[item],
  }));

  return r.sort((a, b) => a.priority - b.priority);
};

export const limitSelectOptions = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 250, label: '250' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' },
  { value: 0, label: 'Все' },
];
