import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ftpListFetch } from '../../../newStore/actions/ftpList';
import { Modal } from '../../Modal/Modal';

import './editGeo.scss';
import Form from './Form';

const EditGeo = () => {
  const [editedGeo, setEditedGeo] = useState(null);
  const { geos } = useSelector((state) => state.update);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ftpListFetch());
  }, []);

  return (
    <Modal id='editGeo' className='edit-geo'>
      {!editedGeo ? (
        <div className='edit-geo__list'>
          <div className='edit-geo__list-head'>
            <div>Name</div>
            <div>Iso</div>
            <div>Visible</div>
          </div>
          {geos.map((item) => (
            <div className='edit-geo__item' key={item.ID} onClick={() => setEditedGeo(item)}>
              <div>{item.name}</div>
              <div>{item.iso}</div>
              <div>
                <div
                  className={classNames('edit-geo__item-flag', {
                    'edit-geo__item-flag--true': !!item.visible,
                  })}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Form geo={editedGeo} reset={() => setEditedGeo(null)} />
      )}
    </Modal>
  );
};

export default EditGeo;
