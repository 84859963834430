import React from 'react';
import { useFieldArray } from 'react-hook-form';
import classNames from 'classnames';
import isString from 'lodash/isString';

import { Button, Input } from '../../baseComponents';

import { ReactComponent as DeleteIcon } from './assets/trash.svg';

export const FieldArray = ({
  control,
  register,
  lang,
  name,
  buttonText,
  fieldsList,
  defaultObj,
  withPriority,
  setDeleteId,
  deleteId,
}) => {
  const { append, remove, fields, move } = useFieldArray({
    control,
    name,
  });

  const handleDeleteClick = (id, idx) => {
    setDeleteId([...deleteId, id]);
    remove(idx);
  };

  const changePriority = (id, action) => {
    if (action === 'up') {
      move(id, id - 1);
    } else if (action === 'down') {
      move(id, id + 1);
    }
  };

  return (
    <div className='current-pwag-field-array'>
      {fields.map((value, idx) => (
        <div className='current-pwag-field-array__item' key={value.id}>
          <div className='current-pwag-field-array__item-controls'>
            {fieldsList.map((item, index) => {
              const As = item.as;

              return isString(As) ? (
                <Input
                  as={item.as}
                  {...register(`${name}[${idx}].${item.value}`)}
                  placeholder={item.placeholder}
                  defaultValue={value[item.value]}
                  key={index}
                />
              ) : (
                <As name={`${name}[${idx}].${item.value}`} key={index} />
              );
            })}
          </div>
          <div className='right-buttons'>
            <button
              type='button'
              className='current-pwag-field-array__item-delete'
              onClick={() => handleDeleteClick(value.ID, idx)}
            >
              <DeleteIcon style={{ width: 20, height: 20 }} />
            </button>
            {withPriority && (
              <>
                <div
                  className={classNames('priority priority--up', {
                    'priority--disabled': idx === 0,
                  })}
                  onClick={() => changePriority(idx, 'up')}
                />
                <div
                  className={classNames('priority priority--down', {
                    'priority--disabled': idx === fields.length - 1,
                  })}
                  onClick={() => changePriority(idx, 'down')}
                />
              </>
            )}
          </div>
        </div>
      ))}
      <Button
        type='button'
        onClick={() => {
          const idx = fields.length;
          append({ ...defaultObj, language: lang });

          if (withPriority) {
            move(idx, 0);
          }
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
};
