import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '../Modal/Modal';

import { deleteDomain } from '../../newStore/actions/domains';
import { closeModal } from '../../newStore/actions/modal';

export const DeleteConfirmModal = ({ domain }) => {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(deleteDomain(domain));
  };

  return (
    <Modal className='delete-confirm' id='delete_confirm--domain'>
      <h2>Подтверждение действия</h2>
      <div className='delete-confirm__buttons'>
        <button
          className='delete-confirm__button'
          onKeyPress={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onClick={onDelete}
        >
          Да
        </button>
        <button
          className='delete-confirm__button'
          onClick={() => dispatch(closeModal('delete_confirm--domain'))}
        >
          Нет
        </button>
      </div>
    </Modal>
  );
};
