import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { openModal } from '../../newStore/actions/modal';
import { fetchSpecialValues } from '../../newStore/actions/specialValues';
import { EditSpecialValues } from './EditSpecialValues';

import './specialValues.scss';

export const SpecialValues = () => {
  const dispatch = useDispatch();

  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    dispatch(fetchSpecialValues());
  }, []);

  const onEdit = (editedId) => {
    setCurrentId(editedId);
    dispatch(openModal('editSpecialValues'));
  };

  const { loading, list } = useSelector((state) => state.specialValues);

  return (
    <div className='special-values'>
      {!loading ? (
        <>
          <div className='special-values__head'>
            <div style={{ visibility: 'hidden' }} />
            <div>Id</div>
            <div>Key</div>
            <div>Value</div>
          </div>
          <div className='special-values__list'>
            {list.map((item, index) => {
              return (
                <div key={index} className='special-values__list-item'>
                  <div className='button-cell'>
                    <button className='one-link__button' onClick={() => onEdit(item.ID)}>
                      Редактировать
                    </button>
                  </div>
                  <div>{`${item.ID}`}</div>
                  <div>{`${item.key}`}</div>
                  <div>{`${item.value}`}</div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <h2>Loading</h2>
      )}
      <EditSpecialValues id={currentId} resetCurrent={() => setCurrentId(null)} />
    </div>
  );
};
