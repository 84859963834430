import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { Button, DeleteButton, Input } from '../../baseComponents';
import { saveLocation } from '../../newStore/actions/locations';
import { cloakGeoOptions } from '../../utils/cloakNum';

import './locationsItem.scss';

const isValueChange = (value, newValue) => JSON.stringify(value) !== JSON.stringify(newValue);

export const LocationsItem = ({ item, setDeletedId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    setValue('name', item.name);
    setValue('cloak_geo_number', item.cloak_geo_number);
  }, [item]);

  const onSubmit = (data) => {
    dispatch(saveLocation({ ...item, ...data }));
  };

  const isButtonDisabled = !isValueChange(
    {
      name: item.name,
      cloak_geo_number: item.cloak_geo_number,
    },
    { name: watch('name'), cloak_geo_number: watch('cloak_geo_number') }
  );

  const onDelete = () => setDeletedId(item.ID);

  return (
    <form className='locations-item' onSubmit={handleSubmit(onSubmit)}>
      <Input {...register('name', { required: true })} placeholder='name' error={!!errors?.name} />
      <Controller
        control={control}
        name='cloak_geo_number'
        render={({ field }) => (
          <Select
            placeholder='cloak_geo_number'
            className='locations-item__select'
            options={cloakGeoOptions}
            onChange={(v) => field.onChange(v.value)}
            value={cloakGeoOptions.find((geo) => geo.value === field.value)}
          />
        )}
      />
      <Button type='submit' disabled={isButtonDisabled}>
        Сохранить
      </Button>
      <DeleteButton onClick={onDelete} />
    </form>
  );
};
