import React from 'react';
import classNames from 'classnames';

import './checkbox.scss';

export const Checkbox = React.forwardRef(
  (
    {
      defaultValue,
      className,
      color,
      name,
      onClick,
      onBlur,
      onChange,
      placeholder,
      style,
      error,
      success,
      label,
      value,
      ...props
    },
    ref
  ) => {
    const classes = classNames('checkbox', className, {
      'checkbox--primary': color === 'primary',
      'checkbox--secondary': color === 'secondary',
      'checkbox--error': !!error,
      'checkbox--success': !!success,
    });

    return (
      <label className={classes} style={{ ...style }}>
        <div className='checkbox__wrap'>
          <input
            ref={ref}
            className='checkbox__control'
            name={name}
            onClick={onClick}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            data-placeholder={placeholder}
            defaultValue={defaultValue || null}
            type='checkbox'
            value={value}
            style={{ display: 'none' }}
            {...props}
          />
          <span className='checkbox__decor' />
        </div>
        {label && (
          <span className='checkbox__label' htmlFor={name}>
            {label}
          </span>
        )}
      </label>
    );
  }
);
