import { pwagsTypes } from '../types/pwags';

const initialState = {
  loading: false,
  list: [],
  current: null,
  languages: [],
  translates: [],
  translateEdit: null,
};

export const pwags = (state = initialState, action) => {
  switch (action.type) {
    case pwagsTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case pwagsTypes.error:
      return {
        ...state,
        loading: false,
      };
    case pwagsTypes.success:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case pwagsTypes.current.loading:
      return {
        ...state,
        loading: true,
      };
    case pwagsTypes.current.error:
      return {
        ...state,
        loading: false,
      };
    case pwagsTypes.current.success:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case pwagsTypes.images.loading:
      return {
        ...state,
        loading: true,
      };
    case pwagsTypes.images.error:
      return {
        ...state,
        loading: false,
      };
    case pwagsTypes.images.success:
      return {
        ...state,
        images: action.payload,
        loading: false,
      };
    case pwagsTypes.images.add:
      return {
        ...state,
        images: [...state.images, action.payload],
      };
    case pwagsTypes.images.delete:
      return {
        ...state,
        images: state.images.filter((item) => item.ID !== action.payload),
      };
    case pwagsTypes.languages.success:
      return {
        ...state,
        languages: action.payload,
      };
    case pwagsTypes.translates.loading:
      return {
        ...state,
        loading: true,
      };
    case pwagsTypes.translates.error:
      return {
        ...state,
        loading: false,
      };
    case pwagsTypes.translates.success:
      return {
        ...state,
        translates: action.payload,
        loading: false,
      };
    case pwagsTypes.translates.setEdit:
      return {
        ...state,
        translateEdit: action.payload,
      };
    case pwagsTypes.translates.save:
      return {
        ...state,
        translates: [...state.translates, action.payload],
      };
    default:
      return state;
  }
};
