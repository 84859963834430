import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { SketchPicker } from 'react-color';

import { Input } from '../Input/Input';

export const FormColorPicker = ({ control, name, rules, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const elementRef = useRef();

  const closeIfClickOutside = useCallback(
    (e) => {
      if (isOpen && !e.composedPath().includes(elementRef.current)) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    window.addEventListener('click', closeIfClickOutside);

    return () => {
      window.removeEventListener('click', closeIfClickOutside);
    };
  }, [isOpen]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <div className='form-color-picker' ref={elementRef}>
          <Input
            onClick={() => setIsOpen((prev) => !prev)}
            placeholder={label}
            value={field.value}
            defaultValue=''
          />
          {isOpen && <SketchPicker color={field.value} onChange={(v) => field.onChange(v.hex)} />}
        </div>
      )}
    />
  );
};
