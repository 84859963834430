import React from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, DeleteButton } from '../../baseComponents';
import { Badge } from '../../baseComponents/Badge/Badge';
import { ChangeGeo } from './ChangeGeo';
import { ChangePrice } from './ChangePrice';

export const Domain = ({
  item,
  setDelete,
  onChangeDefaultPrice,
  onChangeZonePrice,
  onChangeDomainPrice,
  changeStatusDomain,
}) => {
  const dispatch = useDispatch();

  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <>
      <div className='domains__list-head'>
        <Badge className='domains__list-title' size='big' id={item.name}>
          {item.name || 'Empty name'}
        </Badge>
        <ChangePrice price={0} id={item.id} submit={onChangeZonePrice} />
        <ChangePrice
          price={item.default_price}
          id={item.ID}
          submit={() => onChangeDefaultPrice(item)}
        />
        <Button className='domain-toggler' onClick={toggleIsOpen}>
          {isOpen ? 'Скрыть' : 'Открыть'}
        </Button>
      </div>
      <div style={{ maxHeight: isOpen ? 'none' : 0, overflow: 'hidden' }}>
        <div className='domains__head'>
          <div style={{ visibility: 'hidden' }} />
          <div>Domain</div>
          <div>Username</div>
          <div>Activated</div>
          <div>Usd Price</div>
          <div style={{ visibility: 'hidden' }} />
          <div style={{ visibility: 'hidden' }} />
        </div>
        {item.domains.map((domain, idx) => (
          <div className='domains__list-item' key={idx}>
            <div className='button-cell'>
              <DeleteButton onClick={() => setDelete(domain.ID)} />
            </div>
            <div>{domain.domain}</div>
            <div>{domain.temp_username}</div>
            <div>
              <div
                className={classNames('domains__list-item-activated', {
                  'domains__list-item-activated--true': domain.activated,
                })}
              />
            </div>
            <div className='button-cell'>
              <ChangePrice price={domain.usd_price} id={domain.ID} submit={onChangeDomainPrice} />
            </div>
            <div className='button-cell'>
              <Button onClick={() => dispatch(changeStatusDomain(domain.ID))}>
                {domain.activated ? 'Деактивировать' : 'Активировать'}
              </Button>
            </div>
            <div className='button-cell'>
              <ChangeGeo domain={domain} zoneId={item.id} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
