import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { BooleanMarker, Button } from '../../baseComponents';
import { fetchCloBlocks, toggleCloBlocks } from '../../newStore/actions/cloBlock';
import { cloakGeoOptions } from '../../utils/cloakNum';

import './cloBlock.scss';

export const CloBlock = () => {
  const dispatch = useDispatch();

  const { list } = useSelector((state) => state.cloBlock);

  useEffectOnce(() => {
    dispatch(fetchCloBlocks());
  });

  const toggleLinks = (cloak_geo_id) => dispatch(toggleCloBlocks({ flag: 1, cloak_geo_id }));
  const togglePwags = (cloak_geo_id) => dispatch(toggleCloBlocks({ flag: 2, cloak_geo_id }));

  return (
    <div className='clo-block'>
      <div className='clo-block__head'>
        <div className='clo-block__head-item'>cloak</div>
        <div className='clo-block__head-item'>block_links</div>
        <div className='clo-block__head-item'>block_pwags</div>
      </div>
      <div className='clo-block__table'>
        {list.map((item, idx) => (
          <div className='clo-block__table-item' key={idx}>
            <div className='clo-block__table-cell'>
              {cloakGeoOptions.find((cloak) => +cloak.value === item.cloak_geo_id)?.label}
            </div>
            <div className='clo-block__table-cell clo-block__table-cell--toggled'>
              <BooleanMarker value={item.block_links} invert />
              <Button onClick={() => toggleLinks(item.cloak_geo_id)}>Изменить</Button>
            </div>
            <div className='clo-block__table-cell clo-block__table-cell--toggled'>
              <BooleanMarker value={item.block_pwags} invert />
              <Button onClick={() => togglePwags(item.cloak_geo_id)}>Изменить</Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
