import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Select from 'react-select';

import { useQuery } from 'react-query';
import { fetchCurrentUsersList, userBotEventFetch } from '../../newStore/actions/users';
import {
  getBotEventsTypesOptions,
  getAvailableUserListFields,
  prepareBotsEvents,
  prepareUsersList,
} from '../../utils/table';
import { DataFilter } from '../../components/DataFilter';
import { Pagination } from '../../components/Pagination';

import './users.scss';
import { useSavedValue } from '../../hooks/useSavedValue';
import { dataFilterOptions } from '../../utils/dataFilter';
import { api } from '../../api';

const usersLists = [
  'get_payment_users',
  'get_pixel_users',
  'get_inactive_users',
  'get_active_users',
  'get_bot_events',
  'get_tg_users',
];

export const Users = () => {
  const { data: eventTypes } = useQuery('eventsTypes', async () => {
    const { data } = await api.get('get_event_types');
    return data;
  });
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const { value: date, onChange: setDate } = useSavedValue({
    key: 'users_page_date',
    initialValue: dataFilterOptions[0],
  });
  const { value: botEvent, onChange: setBotEvent } = useSavedValue({
    key: 'users_page_bot_event',
    initialValue: getBotEventsTypesOptions(eventTypes)[0]?.value,
  });
  const [visibleFields, setVisibleFields] = useState(false);
  const { list: currentList, loading, pages, secondList } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(
      fetchCurrentUsersList(
        usersLists[activeTab],
        date.value[0],
        date.value[1],
        page,
        limit,
        botEvent < 0 ? null : botEvent
      )
    );

    if (activeTab === 4) {
      dispatch(userBotEventFetch(date.value[0], date.value[1]));
    }
  }, [activeTab, date, page, limit, botEvent]);

  const handleClickTab = (tab) => setActiveTab(tab);

  const list = useMemo(
    () => (!loading ? getAvailableUserListFields(currentList) : []),
    [visibleFields, currentList]
  );

  const onChangeSelect = (val) => {
    localStorage.setItem('users_options', JSON.stringify(val));
  };

  const selectOptions = useMemo(
    () =>
      Object.keys(prepareUsersList(currentList)[0] || {}).map((item) => ({
        label: item,
        value: item,
      })),
    [currentList]
  );

  return (
    <div className='users'>
      <div className='users__filters'>
        <DataFilter setDate={setDate} initialValue={date} />
        {!loading && (
          <Select
            className='current__select'
            defaultValue={JSON.parse(localStorage.getItem('users_options'))}
            onChange={(val) => {
              setVisibleFields((prev) => !prev);
              onChangeSelect(val);
            }}
            options={selectOptions}
            isMulti
            placeholder='Отображаемые поля'
          />
        )}
      </div>
      <div className='users__tabs'>
        <div
          className={classNames('users__tabs-item', {
            'users__tabs-item--active': activeTab === 0,
          })}
          onClick={() => handleClickTab(0)}
        >
          Payment
        </div>
        <div
          className={classNames('users__tabs-item', {
            'users__tabs-item--active': activeTab === 1,
          })}
          onClick={() => handleClickTab(1)}
        >
          Pixel
        </div>
        <div
          className={classNames('users__tabs-item', {
            'users__tabs-item--active': activeTab === 2,
          })}
          onClick={() => handleClickTab(2)}
        >
          Без подписки
        </div>
        <div
          className={classNames('users__tabs-item', {
            'users__tabs-item--active': activeTab === 3,
          })}
          onClick={() => handleClickTab(3)}
        >
          С подпиской
        </div>
        <div
          className={classNames('users__tabs-item', {
            'users__tabs-item--active': activeTab === 4,
          })}
          onClick={() => handleClickTab(4)}
        >
          События
        </div>
        <div
          className={classNames('users__tabs-item', {
            'users__tabs-item--active': activeTab === 5,
          })}
          onClick={() => handleClickTab(5)}
        >
          Пользователи
        </div>
        {activeTab === 4 && (
          <Select
            className='current__select'
            defaultValue={getBotEventsTypesOptions(eventTypes).find(
              (item) => item.value.toString() === String(botEvent)
            )}
            onChange={(val) => {
              setBotEvent(val.value);
            }}
            options={getBotEventsTypesOptions(eventTypes)}
            placeholder='Bot event'
          />
        )}
      </div>
      {!loading && (
        <>
          {activeTab === 4 && (
            <div className='users__second-table'>
              {prepareBotsEvents(secondList, getBotEventsTypesOptions(eventTypes)).map(
                (item, idx) => (
                  <div className='users__second-table-item' key={idx}>
                    <span>{item.title}</span>
                    <span>{item.value}</span>
                  </div>
                )
              )}
            </div>
          )}
          <Pagination
            setPage={setPage}
            setLimit={setLimit}
            limitValue={limit}
            currentPage={page}
            count={pages / limit}
            withInfo
            itemsCount={pages}
          />
          <div
            className={classNames('users__list', {
              'users__list--big': activeTab === 2,
              'users__list--medium': activeTab === 3,
            })}
          >
            <div className='users__list-head'>
              {list[0] &&
                Object.keys(list[0])?.map((item, idx) => (
                  <div className='users__list-head-item' key={idx}>
                    {item}
                  </div>
                ))}
            </div>
            <div className='users__list-body'>
              {list?.map((item, idx) => (
                <div className='users__list-body-item' key={idx}>
                  {Object.keys(item)
                    .filter((filteredItem) => filteredItem !== 'DeletedAt')
                    .map((cell, index) => (
                      <div className='users__list-item-cell' key={index}>
                        {item[cell]}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
          <Pagination
            setPage={setPage}
            setLimit={setLimit}
            limitValue={limit}
            currentPage={page}
            count={pages / limit}
            withInfo
            itemsCount={pages}
          />
        </>
      )}
    </div>
  );
};
