import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { prepareGeosOptions } from '../../utils/create';
import { Button, Checkbox, Input } from '../../baseComponents';
import { Modal } from '../../components/Modal/Modal';
import { createOnelink, oneLinkGeosFetch } from '../../newStore/actions/onelink';
import { typesList } from './AddOnelink.data';

export const CloneOnelink = ({ id, resetCurrent }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { list, geos } = useSelector((state) => state.onelinks);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const currentOnelink = list.find((item) => item.ID === id);

  const onSubmit = (data) => {
    dispatch(createOnelink({ ...data }));

    setError(null);
  };

  useEffect(() => {
    if (currentOnelink) {
      setValue('geos', currentOnelink.geos || []);
    }
  }, [currentOnelink]);

  const onReset = () => {
    resetCurrent();
    reset();
    setError(null);
  };

  useEffect(() => {
    dispatch(oneLinkGeosFetch());
  }, []);

  return (
    <Modal id='cloneOnelink' onHide={onReset}>
      <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
      <form className='add-form' onSubmit={handleSubmit(onSubmit)}>
        <Checkbox label='visible' {...register('visible')} />
        <Checkbox label='Use_after_ban' {...register('use_after_ban')} />
        <Input placeholder='app_name' {...register('app_name', { required: true })} />
        <Input placeholder='pwd' {...register('pwd')} />
        <Input placeholder='key' {...register('key')} />
        <Input placeholder='link' {...register('link')} />
        <Input placeholder='package' {...register('package', { required: true })} />
        <Input placeholder='description' {...register('description')} />
        <label className='input-label' htmlFor='geos'>
          Geos
        </label>
        <Controller
          control={control}
          name='geos'
          render={({ ref, field }) => (
            <Select
              className='create__geos'
              classNamePrefix='create__geos'
              inputRef={ref}
              menuPlacement='top'
              isMulti
              options={prepareGeosOptions(geos)}
              value={prepareGeosOptions(geos).filter((geoItem) =>
                Array.isArray(field.value)
                  ? field.value?.find((item) => item.name === geoItem.label)
                  : []
              )}
              onChange={(val) => field.onChange(val.map((item) => item.value))}
            />
          )}
        />
        <label className='input-label' htmlFor='type'>
          Type
        </label>
        <Controller
          control={control}
          name='type'
          render={({ ref, field }) => (
            <>
              <Select
                className='create__type'
                inputRef={ref}
                options={typesList}
                type='text'
                menuPlacement='top'
                value={typesList.find((item) => item.value === field.value)}
                onChange={(val) => field.onChange(val.value)}
                placeholder='type'
              />
              <span style={{ color: 'red' }}>{errors.type?.message}</span>
            </>
          )}
        />
        <Button className='add-form__button' type='submit' style={{ marginTop: 15 }}>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
