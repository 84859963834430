import { forIn } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, Input } from '../../baseComponents';
import { Modal } from '../../components';
import { addManager, editManager } from '../../newStore/actions/managers';

export const Form = ({ edited, onClose }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (edited) {
      forIn(edited, (value, key) => setValue(key, value));
    }
  }, [edited]);

  const onSubmit = (data) => {
    if (edited) {
      dispatch(editManager(data));
    } else {
      dispatch(addManager(data));
    }
  };

  return (
    <Modal className='managers-form' id='managers-form' onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder='login'
          {...register('login', { required: true })}
          error={errors.login}
        />
        <Input placeholder='password' {...register('password')} error={errors.login} />
        <Checkbox label='admin' {...register('admin')} />
        <Checkbox label='ac_pwag_del' {...register('ac_pwag_del')} />
        <Checkbox label='ac_pwag_upd' {...register('ac_pwag_upd')} />
        <Checkbox label='ac_streams' {...register('ac_streams')} />
        <Checkbox label='ac_page' {...register('ac_page')} />
        <Button type='submit'>Сохранить</Button>
      </form>
    </Modal>
  );
};
