import { affilatesTypes } from '../types/affilates';

const initialState = {
  list: [],
  loading: false,
  count: 0,
  current: {},
  table: [],
};

export const affilates = (state = initialState, action) => {
  switch (action.type) {
    case affilatesTypes.list.loading:
      return {
        ...state,
        loading: true,
      };
    case affilatesTypes.list.success:
      return {
        ...state,
        loading: false,
        list: action.payload.affilates,
        count: action.payload.count,
      };
    case affilatesTypes.list.error:
      return {
        ...state,
        loading: false,
      };
    case affilatesTypes.clients.success:
      return {
        ...state,
        table: action.payload.list,
        tableCount: action.payload.count,
      };
    default:
      return state;
  }
};
