/* eslint-disable indent */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { Controller, useForm } from 'react-hook-form';
import { Modal } from '../../components/Modal';
import { Button, Fields } from '../../baseComponents';
import { savePwagsTranslations } from '../../newStore/actions/pwag';
import { pwagsTypes } from '../../newStore/types/pwags';

export const AddPwagTranslate = () => {
  const dispatch = useDispatch();
  const pwagsTranslations = useSelector((state) => state.pwags.translates);
  const item = useSelector((state) => state.pwags.translateEdit);
  const langs = useSelector((state) => state.pwags.languages);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const excludedLangs = pwagsTranslations?.reduce(
    (acc, t) => (item?.ID === t.ID ? acc : [...acc, t.language]),
    []
  );

  const langsOptions = langs.reduce(
    (acc, val) =>
      excludedLangs.includes(val.iso)
        ? acc
        : [
            ...acc,
            {
              label: val.name,
              value: val.iso,
            },
          ],
    []
  );

  useEffect(() => item && Object.keys(item).forEach((key) => setValue(key, item[key])), [item]);

  const fieldsConfig = [
    {
      type: 'input',
      name: 'install_btn',
      placeholder: 'install_btn',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'installing_btn',
      placeholder: 'installing_btn',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'initialization_btn',
      placeholder: 'initialization_btn',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'open_btn',
      placeholder: 'open_btn',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'loading_btn',
      placeholder: 'loading_btn',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'more_detailed',
      placeholder: 'opmore_detaileden_btn',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'rating',
      placeholder: 'rating',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'ratings_and_reviews',
      placeholder: 'ratings_and_reviews',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'additional_information',
      placeholder: 'additional_information',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'updated',
      placeholder: 'updated',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'size',
      placeholder: 'size',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'installs',
      placeholder: 'installs',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'current_version',
      placeholder: 'current_version',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'developer',
      placeholder: 'developer',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'games',
      placeholder: 'games',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'less',
      placeholder: 'less',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'reviews',
      placeholder: 'reviews',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'yes',
      placeholder: 'yes',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'no',
      placeholder: 'no',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'eighteen',
      placeholder: 'eighteen',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'more',
      placeholder: 'more',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'ttmodal',
      placeholder: 'ttmodal',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'downloads_count',
      placeholder: 'downloads_count',
      rules: { required: true },
    },
    {
      type: 'input',
      name: 'downloads',
      placeholder: 'downloads',
      rules: { required: true },
    },
  ];

  const onSubmit = (data) => dispatch(savePwagsTranslations(data));

  const onHide = () => {
    dispatch({
      type: pwagsTypes.translates.setEdit,
      payload: null,
    });
    reset();
  };

  return (
    <Modal id='add-pwag-translate' className='add-pwag-translate' onHide={onHide}>
      <form className='add-pwag-translate__form' onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          rules={{ required: true }}
          name='language'
          render={({ field }) => (
            <Select
              options={langsOptions}
              value={langsOptions.find((lang) => lang.value === field.value)}
              onChange={(v) => field.onChange(v.value)}
            />
          )}
        />
        <Fields itemInRow={3} errors={errors} register={register} config={fieldsConfig} />
        <Button className='add-pwag-translate__form-submit' type='submit'>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
