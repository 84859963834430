import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../newStore/actions/modal';
import { pwagsImagesFetch } from '../../newStore/actions/pwag';
import { getImagePath } from '../../utils/cloakNum';

import { DeleteConfirm } from './ConfirmDelete';

import './images.scss';

export const Images = () => {
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(null);
  const images = useSelector((state) => state.pwags.images);

  useEffect(() => {
    if (!images) {
      dispatch(pwagsImagesFetch());
    }
  }, [images]);

  const onImageClick = (id) => {
    setDeleteId(id);
    dispatch(openModal('delete_confirm--image'));
  };

  return (
    <div className='images-page'>
      <div className='images-page__list'>
        {images?.map((item) => (
          <div
            className='images-page__list-item'
            key={item.ID}
            onClick={() => onImageClick(item.ID)}
            aria-hidden='true'
          >
            <img src={getImagePath(item.name)} alt={item.name} />
          </div>
        ))}
      </div>
      <DeleteConfirm id={deleteId} />
    </div>
  );
};
