import { whitePageTypes } from '../types/whitepage';

const initialState = {
  loading: false,
  file: '',
  error: '',
};

export const whitePage = (state = initialState, action) => {
  switch (action.type) {
    case whitePageTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case whitePageTypes.reject:
      return {
        ...state,
        loading: false,
      };
    case whitePageTypes.success:
      return {
        ...state,
        loading: false,
        file: action.payload,
      };
    default:
      return state;
  }
};
