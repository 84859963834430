import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ftpListFetch } from '../../../newStore/actions/ftpList';
import { Modal } from '../../Modal/Modal';
import { DeleteButton } from '../../../baseComponents';

import './ftpList.scss';
import { openModal } from '../../../newStore/actions/modal';
import { DeleteConfirmModal } from './Confirg';

const FtpList = () => {
  const [deleteId, setDeleteId] = useState(null);
  const { list, loading } = useSelector((state) => state.ftpList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ftpListFetch());
  }, []);

  const onDelete = (id) => {
    setDeleteId(id);
    dispatch(openModal('delete_confirm--ftp'));
  };

  return (
    <Modal id='ftpList'>
      {!loading ? (
        <ul className='ftp-list'>
          {list.map((item, index) => (
            <li className='ftp-list__item' key={index}>
              <DeleteButton onClick={() => onDelete(item.ID)} />
              {item.name}
            </li>
          ))}
        </ul>
      ) : (
        <h2>Loading</h2>
      )}
      <DeleteConfirmModal id={deleteId} />
    </Modal>
  );
};

export default FtpList;
