/* eslint-disable no-console */
import { api } from '../../api';
import { iosAppTypes } from '../types/iosApp';
import { openModal } from './modal';

export const fetchIosApps = () => async (dispatch) => {
  try {
    const { data } = await api.get('/get_ios_apps');

    dispatch({ type: iosAppTypes.set, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const createIosApps = (value) => async (dispatch) => {
  try {
    const { data } = await api.post('/create_ios_app', value);

    dispatch({ type: iosAppTypes.add, payload: data });
    dispatch(openModal('success'));
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};

export const updateIosApps = (value) => async (dispatch) => {
  try {
    const { data } = await api.post('/update_ios_app', value);

    dispatch({ type: iosAppTypes.edit, payload: data });
    dispatch(openModal('success'));
  } catch (e) {
    console.log(e);
    dispatch(openModal('error'));
  }
};
