import { profitTypes } from '../types/profit';

const initialState = {
  loading: false,
  list: [],
  summ: 0,
};

export const profit = (state = initialState, action) => {
  switch (action.type) {
    case profitTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case profitTypes.reject:
      return {
        ...state,
        loading: false,
      };
    case profitTypes.success:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        count: action.payload.count,
        summ: action.payload.summ,
      };
    default:
      return state;
  }
};
