import { domainsTypes, domainZonesTypes } from '../types/domains';

const initialState = {
  list: [],
  domainZonesList: [],
  loading: false,
  zonesLoading: false,
};

export const domains = (state = initialState, action) => {
  switch (action.type) {
    case domainsTypes.loading:
      return {
        ...state,
        loading: true,
      };
    case domainsTypes.success:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        domainZonesList: action.payload.domainZonesList,
      };
    case domainsTypes.error:
      return {
        ...state,
        loading: false,
      };
    case domainZonesTypes.success:
      return {
        ...state,
        domainZonesList: action.payload,
      };
    case domainZonesTypes.create:
      return {
        ...state,
        zonesLoading: true,
      };
    case domainZonesTypes.createSuccess:
      return {
        ...state,
        zonesLoading: false,
      };
    case domainsTypes.update:
      return {
        ...state,
        list: state.list.map((item) =>
          action.payload.zoneId !== item.id
            ? item
            : {
                ...item,
                domains: item.domains.filter((domain) => domain.ID !== action.payload.domain.ID),
              }
        ),
      };
    default:
      return state;
  }
};
