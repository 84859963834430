import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { Button, Input } from '../../baseComponents';
import { addLocation } from '../../newStore/actions/locations';
import { cloakGeoOptions } from '../../utils/cloakNum';

export const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(addLocation(data));
  };

  return (
    <form className='locations-add' onSubmit={handleSubmit(onSubmit)}>
      <Input {...register('name', { required: true })} placeholder='name' error={!!errors?.name} />
      <Controller
        control={control}
        name='cloak_geo_number'
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            placeholder='cloak_geo_number'
            className='locations-add__select'
            options={cloakGeoOptions}
            onChange={(v) => field.onChange(v.value)}
            value={cloakGeoOptions.find((item) => item.value === field.value)}
          />
        )}
      />
      <Button type='submit'>Сохранить</Button>
    </form>
  );
};
